import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

type NotificationDataType = {
  data: Array<[]>
  total: number
}

interface NotificationSliceState {
  allData: NotificationDataType
  unReadData: NotificationDataType
  loading: boolean
  totalUnread: number
}

const initialState: NotificationSliceState = {
  allData: {
    data: [],
    total: 0,
  },
  unReadData: {
    data: [],
    total: 0,
  },
  loading: false,
  totalUnread: 0,
}

const NotificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateAllNotificationData(
      state,
      action: PayloadAction<NotificationDataType>,
    ) {
      return {
        ...state,
        allData: {
          ...action.payload,
          data: [...state.allData.data, ...action.payload.data],
        },
      }
    },
    updateNewNotificationData(
      state,
      action: PayloadAction<any>,
    ) {
      return {
        ...state,
        allData: {
          ...state.allData,
          data: [action.payload.data, ...state.allData.data],
        },
      }
    },
    updateUnreadNotificationData(
      state,
      action: PayloadAction<NotificationDataType>,
    ) {
      return {
        ...state,
        unReadData: {
          ...action.payload,
          data: [...state.unReadData.data, ...action.payload.data],
        },
      }
    },
    updateLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      }
    },
    updateTotalUnread(state, action: PayloadAction<number>) {
      return {
        ...state,
        totalUnread: action.payload,
      }
    },
    resetNotificationState(state) {
      return {
        ...initialState,
        totalUnread: state.totalUnread,
      }
    },
  },
})

export const {
  updateAllNotificationData,
  updateNewNotificationData,
  updateUnreadNotificationData,
  updateLoading,
  resetNotificationState,
  updateTotalUnread,
} = NotificationSlice.actions

export default NotificationSlice.reducer
