import { createElement, cloneElement } from "react";

export default function defineElement(element, props = {}) {
  if (element) {
    return typeof element === "function"
      ? createElement(element, props)
      : cloneElement(element, props);
  }

  return null;
}
