import { combineReducers } from "@reduxjs/toolkit"
import chatBoxReducer from "./chatbot/ChatBoxSlice"
import chatMsgReducer from "./chatbot/ChatMsgSlice"
import AlertsReducer from "./defiLens/AlertsSlice"
import BacktestReducer from "./defiLens/BacktestSlice"
import ColumnsCustomReducer from "./defiLens/ColumnsCustomSlice"
import lensReducer from "./defiLens/LensSlice"
import lenssetSavedReducer from "./defiLens/LenssetSavedSlice"
import NotificationReducer from "./defiLens/NotificationSlice"
import PoolRankReducer from "./defiLens/PoolRankSlice"
import PoolWatchListResucer from "./defiLens/PoolWatchListSlice"
import QuickTradeReducer from "./defiLens/QuickTradeSlice"
import tokenDetailReducer from "./defiLens/TokenDetailSlice"
import tokensRankReducer from "./defiLens/TokensRankSlice"
import TypesRankingReducer from "./defiLens/TypesRankingSlice"
import WatchListReducer from "./defiLens/WatchListSlice"
import LensKeysReducer from "./defiLens/LensKeysSlice"
import ExpandReducer from "./defiLens/ExpandTableSlice.ts"
import MockTradingReducer from "./defiLens/MockTradingSlice.ts"
import PathNameReducer from "./defiLens/PathNameSlice.ts"

const rootReducer = combineReducers({
  chatMsg: chatMsgReducer,
  chatBox: chatBoxReducer,
  lens: lensReducer,
  tokensRank: tokensRankReducer,
  tokenDetail: tokenDetailReducer,
  watchList: WatchListReducer,
  lenssetSaved: lenssetSavedReducer,
  poolRank: PoolRankReducer,
  poolWatchList: PoolWatchListResucer,
  typesRanking: TypesRankingReducer,
  columnsCustom: ColumnsCustomReducer,
  quickTrade: QuickTradeReducer,
  notifications: NotificationReducer,
  alerts: AlertsReducer,
  backtest: BacktestReducer,
  lensKeys: LensKeysReducer,
  expandTable: ExpandReducer,
  mockTrading: MockTradingReducer,
  pathnameLocal: PathNameReducer,
})

export default rootReducer
