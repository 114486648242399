import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

export type TradingStyleType = {
  type: string
  title: string
  desc?: string
  status?: boolean
}

type SignalDataType = {
  data: any[]
  total: number
  version?: string
}
interface QuickTradeSliceState {
  tradingStyle: TradingStyleType
  signalData: SignalDataType
  loading: boolean
  signalFilterData: any[]
  backTestValue: any
  marketPrice: any[]
}

const initialState: QuickTradeSliceState = {
  tradingStyle: {
    type: "",
    title: "",
  },
  signalData: {
    data: [],
    total: 0,
    version: "",
  },
  loading: false,
  signalFilterData: [],
  backTestValue: null,
  marketPrice: [],
}

const QuickTradeSlice = createSlice({
  name: "quick-trade",
  initialState,
  reducers: {
    updateTradingStyle: (state, action: PayloadAction<TradingStyleType>) => {
      return {
        ...state,
        tradingStyle: action.payload,
      }
    },

    updateSignalsData(state, action: PayloadAction<SignalDataType>) {
      return {
        ...state,
        signalData: action.payload,
      }
    },

    updateSignalsFilterData(state, action: PayloadAction<any[]>) {
      return {
        ...state,
        signalFilterData: action.payload,
      }
    },

    updateLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      }
    },

    updateBackTestDetailValue(state, action: PayloadAction<any>) {
      return {
        ...state,
        backTestValue: action.payload,
      }
    },

    updateMarketPrice(state, action: PayloadAction<any>) {
      return {
        ...state,
        marketPrice: action.payload,
      }
    },

    resetQuickTradeState() {
      return initialState
    },
  },
})

export const {
  updateTradingStyle,
  updateSignalsData,
  updateSignalsFilterData,
  updateLoading,
  resetQuickTradeState,
  updateBackTestDetailValue,
  updateMarketPrice,
} = QuickTradeSlice.actions

export default QuickTradeSlice.reducer
