import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface WatchListState {
  isWatchListModalOpen: boolean
  loading: boolean
  favoriteList: any[]
  watchListInfo: any
  favoriteInWatchList: any[]
}

const initialState: WatchListState = {
  isWatchListModalOpen: false,
  loading: false,
  favoriteList: [],
  favoriteInWatchList: [],
  watchListInfo: {
    page: 1,
    offset: 0,
    size: 10,
    total: 0,
  },
}

const WatchListSlice = createSlice({
  name: "watch-list",
  initialState,
  reducers: {
    updateStatusFavoriteModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isWatchListModalOpen: action.payload,
      }
    },

    updateFavoriteList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        favoriteList: action.payload,
      }
    },

    updateWatchListInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        watchListInfo: {
          ...state.watchListInfo,
          ...action.payload,
        },
      }
    },

    updateFavoriteInWatchList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        favoriteInWatchList: action.payload,
      }
    },
  },
})

export const {
  updateStatusFavoriteModal,
  updateFavoriteList,
  updateWatchListInfo,
  updateFavoriteInWatchList,
} = WatchListSlice.actions

export default WatchListSlice.reducer
