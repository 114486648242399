import { PATHS_NAME } from "@constants/index"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  pathName: PATHS_NAME.DEFI_LENS,
}

const PathNameSlice = createSlice({
  name: "path-name-local",
  initialState,
  reducers: {
    updatePathNameLocal(state, action: PayloadAction<string>) {
      return {
        ...state,
        pathName: action.payload,
      }
    },
  },
})

export const { updatePathNameLocal } = PathNameSlice.actions

export default PathNameSlice.reducer
