import {
  MARKET_LENS,
  MOMEMTUM_LENS,
  PATTERN_LENS,
  POOL_LENS,
  PoolLenssetTypes,
  SOCIAL_LENS,
  TokenLenssetTypes,
  TypesRanking,
} from "@constants/defiLens"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { LensMode } from "types/defiLens"

export type LensItem = {
  logoUrl?: string
  name: string
  key: string
  enabled: boolean
  range?: Array<number>
  value_by_range?: Array<number>
  unit?: string
  desrc?: string
  minLevel?: string
  maxLevel?: string
  mainUnit?: string
  type: string
  rangeType?: string
  // selectList: [
  //   {
  //     label: string
  //     value: string
  //     icon: string
  //   },
  // ]
  valueSaved?: any
  [key: string]: any
}

const { TOKEN, POOL } = TypesRanking

export interface LensDataState {
  [typeRankingKey: string]: {
    [key: string]: {
      title: string
      list: LensItem[]
    }
  }
}

interface DefiLensState {
  lensData: LensDataState
  lensList: Array<LensItem>
  isLoadingLens: boolean
  lensMode: LensMode
}

export const lensDataDefault = {
  [TOKEN]: {
    [TokenLenssetTypes.MARKET]: {
      title: "Market",
      list: MARKET_LENS,
    },
    [TokenLenssetTypes.SOCIAL]: {
      title: "Social",
      list: SOCIAL_LENS,
    },
    [TokenLenssetTypes.MOMEMTUM]: {
      title: "TA Indicators",
      list: MOMEMTUM_LENS,
    },
    [TokenLenssetTypes.PATTERN]: {
      title: "Patterns",
      list: PATTERN_LENS,
    },
  },
  [POOL]: {
    [PoolLenssetTypes.POOL]: {
      title: "",
      list: POOL_LENS,
    },
  },
}

const initStateValues = {
  lensData: lensDataDefault,
  lensList: [],
  isLoadingLens: false,
  lensMode: LensMode.FILTER,
}

const initialState: DefiLensState = initStateValues

const LensSlice = createSlice({
  name: "lens",
  initialState,
  reducers: {
    updateLensData: (state, action: PayloadAction<LensDataState>) => {
      return {
        ...state,
        lensData: action.payload,
      }
    },
    addMoreLens: (state, action: PayloadAction<Array<LensItem>>) => {
      const mergedArray = [
        ...new Map(
          [...action.payload].map((item) => [item.key, item]),
        ).values(),
      ].filter((item) => item.enabled)
      return {
        ...state,
        lensList: mergedArray,
      }
    },
    updateLoadingLens: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoadingLens: action.payload,
      }
    },
    updateLensMode: (state, action: PayloadAction<LensMode>) => {
      return {
        ...state,
        lensMode: action.payload,
      }
    },
    setLenValuesDefault: (state) => {
      return {
        ...state,
        ...initStateValues,
      }
    },
  },
})

export const {
  updateLensData,
  addMoreLens,
  updateLoadingLens,
  updateLensMode,
  setLenValuesDefault,
} = LensSlice.actions

export default LensSlice.reducer
