import axios, { AxiosError, AxiosResponse } from "axios"
import TokenService from "./token"

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PATCH: "PATCH",
  DELETE: "DELETE",
}
const baseLLMURL = import.meta.env.VITE_APP_BASE_LLM_URL

export const fetchApi = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
})

export const fetchApiAuth = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
})

fetchApiAuth.interceptors.request.use(
  async (config) => {
    const accessToken = TokenService.getLocalAccessToken()
    if (!accessToken) {
      return config
    }
    config.headers.Authorization = `Bearer ${accessToken}`
    return config
  },
  (error: AxiosError) => {
    return Promise.reject(error)
  },
)

const dispatchEventStorage = () => {
  const event = new StorageEvent("storage", {
    key: "logout",
  })
  return window.dispatchEvent(event)
}

fetchApiAuth.interceptors.response.use(
  (res: AxiosResponse) => {
    return res
  },
  async (error) => {
    const originalRequest = error.config
    if (originalRequest.url !== "/auth/login" && error.response) {
      // Access Token was expired
      const token = TokenService.getLocalToken()
      const currentTimestamp = Math.floor(Date.now() / 1000)
      if (token && token.expireAt && token.expireAt < currentTimestamp) {
        if (token.refreshToken && !originalRequest._retry) {
          originalRequest._retry = true
          try {
            const config = {
              baseURL: baseLLMURL,
              url: "/auth/refreshtoken",
              method: METHODS.POST,
              data: {
                refreshToken: token.refreshToken,
              },
            }
            const rs = await fetchApi(config)
            TokenService.updateLocalNewToken(rs.data?.token)
            return fetchApiAuth(originalRequest)
          } catch (_error) {
            dispatchEventStorage()
            return Promise.reject(_error)
          }
        } else {
          dispatchEventStorage()
        }
      }
    }
    return Promise.reject(error)
  },
)
