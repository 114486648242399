import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface BacktestState {
  signalStrategyData: any[]
  exitStrategyData: any[]
  positionsData: any[]
  overviewData: any
  listOfTrade: {
    data: any[]
    total: number
  }
}

const initialState: BacktestState = {
  signalStrategyData: [],
  exitStrategyData: [],
  positionsData: [],
  overviewData: {},
  listOfTrade: {
    data: [],
    total: 0,
  },
}

const BacktestSlice = createSlice({
  name: "backtest",
  initialState,
  reducers: {
    updateSignalStrategy(
      state,
      action: PayloadAction<BacktestState["signalStrategyData"]>,
    ) {
      return {
        ...state,
        signalStrategyData: action.payload,
      }
    },

    updateExitStrategy(
      state,
      action: PayloadAction<BacktestState["exitStrategyData"]>,
    ) {
      return {
        ...state,
        exitStrategyData: action.payload,
      }
    },

    updatePositions(
      state,
      action: PayloadAction<BacktestState["positionsData"]>,
    ) {
      return {
        ...state,
        positionsData: action.payload,
      }
    },

    updateOverview(
      state,
      action: PayloadAction<BacktestState["overviewData"]>,
    ) {
      return {
        ...state,
        overviewData: action.payload,
      }
    },

    updateListOfTrade(
      state,
      action: PayloadAction<BacktestState["listOfTrade"]>,
    ) {
      return {
        ...state,
        listOfTrade: action.payload,
      }
    },

    resetBacktest() {
      return initialState
    },
  },
})

export const {
  updateSignalStrategy,
  updateExitStrategy,
  updatePositions,
  updateOverview,
  updateListOfTrade,
  resetBacktest,
} = BacktestSlice.actions

export default BacktestSlice.reducer
