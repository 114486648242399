import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface MockTradingSliceState {
  isDescMockTrading?: boolean
}

const initialState: MockTradingSliceState = {
  isDescMockTrading: false,
}

const MockTradingSlice = createSlice({
  name: "mock-trading",
  initialState,
  reducers: {
    updateDescMockTrading(state, action: PayloadAction<any>) {
      return {
        ...state,
        isDescMockTrading: action.payload,
      }
    },
  },
})

export const { updateDescMockTrading } = MockTradingSlice.actions

export default MockTradingSlice.reducer
