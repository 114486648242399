import { PATHS_NAME } from "@constants/index"
import { TypesRanking } from "./../../constants/defiLens"

import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface TypesRankingState {
  typeRanking: TypesRanking
}

const initialState: TypesRankingState = {
  typeRanking:
    window.location.pathname === PATHS_NAME.DEFI_LENS_POOL_RANKING
      ? TypesRanking.POOL
      : TypesRanking.TOKEN,
}

const TypesRankingSlice = createSlice({
  name: "types-ranking",
  initialState,
  reducers: {
    updateTypesRanking(state, action: PayloadAction<any>) {
      return {
        ...state,
        typeRanking: action.payload,
      }
    },
  },
})

export const { updateTypesRanking } = TypesRankingSlice.actions

export default TypesRankingSlice.reducer
