import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { LensItem } from "./LensSlice"

export type SavedLenssetItem = {
  createdAt?: string
  data?: any
  id?: string
  name?: string
  updatedAt?: string
  userId?: string
  type?: string
}

interface LenssetSavedState {
  savedLenssetList: Array<SavedLenssetItem>
  isLoading: boolean
  savedlensSelected: SavedLenssetItem
  isFetch: boolean
  isEditSavedValue: boolean
  isLoadingPreview: boolean
  lensListByName: Array<LensItem>
}

const initialState: LenssetSavedState = {
  savedLenssetList: [],
  isLoading: false,
  savedlensSelected: {
    createdAt: "",
    data: {},
    id: "",
    name: "",
    updatedAt: "",
    userId: "",
  },
  isFetch: false,
  isEditSavedValue: false,
  isLoadingPreview: false,
  lensListByName: [],
}

const LenssetSavedSlice = createSlice({
  name: "lensset-saved",
  initialState,
  reducers: {
    updateSavedLenssetList: (
      state,
      action: PayloadAction<Array<SavedLenssetItem>>,
    ) => {
      return {
        ...state,
        savedLenssetList: action.payload,
      }
    },
    updateSavedLenssetLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
    updateIsFetch: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isFetch: action.payload,
      }
    },
    updateIsEditSavedValue: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isEditSavedValue: action.payload,
      }
    },
    updateLenssetPreviewLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoadingPreview: action.payload,
      }
    },
    updateSavedLenssetSelected: (
      state,
      action: PayloadAction<SavedLenssetItem>,
    ) => {
      return {
        ...state,
        savedlensSelected: action.payload,
      }
    },
    updateLensListByName: (state, action: PayloadAction<Array<LensItem>>) => {
      return {
        ...state,
        lensListByName: action.payload,
      }
    },
  },
})

export const {
  updateSavedLenssetList,
  updateSavedLenssetLoading,
  updateSavedLenssetSelected,
  updateIsFetch,
  updateLensListByName,
  updateLenssetPreviewLoading,
  updateIsEditSavedValue,
} = LenssetSavedSlice.actions

export default LenssetSavedSlice.reducer
