import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { LensMode } from "types/defiLens"

interface TokensRankState {
  tokenList: Array<any>
  isLoading: boolean
  filtersToken: {
    keyword: string
    page: number
    offset: number
    total: number
    mode: LensMode
    [key: string]: any
  }
}

const initialState: TokensRankState = {
  tokenList: [],
  isLoading: false,
  filtersToken: {
    keyword: "",
    page: 1,
    offset: 0,
    size: 50,
    total: 0,
    mode: LensMode.FILTER,
  },
}

const TokensRankSlice = createSlice({
  name: "tokens-ranking",
  initialState,
  reducers: {
    updateTokenList: (state, action: PayloadAction<[]>) => {
      return {
        ...state,
        tokenList: action.payload,
      }
    },
    updateTokensLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
    setTableTokensDefault: (state) => {
      return {
        ...state,
        ...initialState,
      }
    },
    updateFiltersToken: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        filtersToken: {
          ...state.filtersToken,
          ...action.payload,
        },
      }
    },
  },
})

export const {
  updateTokenList,
  updateTokensLoading,
  setTableTokensDefault,
  updateFiltersToken,
} = TokensRankSlice.actions

export default TokensRankSlice.reducer
