import { PATHS_NAME } from "@constants/index"
import { lazy } from "react"
import { RouteObject, useRoutes } from "react-router-dom"
import { RequireAuth } from "./RequireAuth"

const Home = lazy(() => import("pages/Home"))
const Chatbot = lazy(() => import("pages/Chatbot"))

const RoutesConfig = (props) => {
  const isAuthenticated = props.isAuthenticated
  const isLoginLoading = props.isLoginLoading

  const routers: RouteObject[] = [
    { path: "/", element: <Home isLoginLoading={isLoginLoading} /> },
    {
      path: PATHS_NAME.LLM,
      element: (
        <RequireAuth isAuthenticated={isAuthenticated}>
          <Chatbot isAuthenticated={isAuthenticated} />
        </RequireAuth>
      ),
    },
  ]

  const elements = useRoutes(routers)
  return elements
}

export default RoutesConfig
