import { AppState, Auth0Provider, User } from "@auth0/auth0-react"
import * as Sentry from "@sentry/react"
import Providers from "Providers.tsx"
import React from "react"
import ReactDOM from "react-dom/client"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import TokenService from "services/token.ts"
import App from "./App.tsx"
import "./index.css"

const domain =
  import.meta.env.VITE_APP_AUTH0_DOMAIN || "dev-2k2cs6baiq7edxks.us.auth0.com"
const clientId = import.meta.env.VITE_APP_AUTH0_CLIENT_ID
const sentryDSN = import.meta.env.VITE_APP_SENTRY_DSN
const isDevelopment = import.meta.env.VITE_APP_ENV_MODE == "staging"

const onRedirectCallback = async (appState?: AppState, user?: User) => {
  TokenService.setUserLocal({
    ...user,
    referredByCode: appState?.referredByCode,
  })
}

const getRedirectURI = () => {
  new URL(location.href).searchParams.get("referredByCode")
  const params = new URL(location.href).searchParams
  const referredByCode = params.get("referredByCode")

  if (referredByCode) {
    return `${window.location.origin}/?referredByCode=${referredByCode}`
  }

  return window.location.origin
}

Sentry.init({
  dsn: sentryDSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Sentry.Replay(),
    new Sentry.Integrations.GlobalHandlers({
      onerror: true,
      onunhandledrejection: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["https://defilens.ai/"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: !isDevelopment,
  ignoreErrors: [
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
  ],
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    authorizationParams={{
      redirect_uri: getRedirectURI(),
      // audience: audience || "https://layer.orai.io",
      prompt: "select_account",
    }}
    onRedirectCallback={onRedirectCallback}
  >
    <Providers>
      <App />
    </Providers>
    <ToastContainer toastClassName="rounded-xl shadow-3 text-16 font-semibold text-gray-dark-charcoal font-sf-pro-display" />
  </Auth0Provider>,
)
