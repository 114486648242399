import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface ICurrentPair {
  tokenId: string
  symbol: string
}

export enum CHART_TYPES {
  AI_EXPLAINER = "ai_explainer",
  SIMILAR = "similar",
}

interface ITokenDetailState {
  isShowDetail: boolean
  currentPair: ICurrentPair
  createdAt: string
  switchChart: {
    [AI_EXPLAINER]: boolean
    [SIMILAR]: boolean
  }
  chartType: string
  isLandingPage: boolean
}

const { AI_EXPLAINER, SIMILAR } = CHART_TYPES

const initialState: ITokenDetailState = {
  isShowDetail: false,
  currentPair: {
    tokenId: "",
    symbol: "",
  },
  createdAt: "",
  switchChart: {
    [AI_EXPLAINER]: false,
    [SIMILAR]: false,
  },
  chartType: AI_EXPLAINER,
  isLandingPage: false,
}

const TokenDetailSlice = createSlice({
  name: "token-detail",
  initialState,
  reducers: {
    setShowDetailToken(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        isShowDetail: action.payload,
      }
    },
    setTokenId(state, action: PayloadAction<ICurrentPair>) {
      return {
        ...state,
        currentPair: {
          ...state.currentPair,
          ...action.payload,
        },
      }
    },
    setCreatedAt(state, action: PayloadAction<string>) {
      return {
        ...state,
        createdAt: action.payload,
      }
    },
    updateSwitchChart: (
      state,
      action: PayloadAction<{
        key: string
        value: boolean
      }>,
    ) => {
      return {
        ...state,
        switchChart: {
          [AI_EXPLAINER]: false,
          [SIMILAR]: false,
          [action.payload.key]: action.payload.value,
        },
      }
    },
    updateChartType: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        chartType: action.payload,
      }
    },
    updateIsLandingPage: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLandingPage: action.payload,
      }
    },
  },
})

export const {
  setShowDetailToken,
  setTokenId,
  setCreatedAt,
  updateSwitchChart,
  updateChartType,
  updateIsLandingPage,
} = TokenDetailSlice.actions

export default TokenDetailSlice.reducer
