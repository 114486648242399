import { ChartTypeDataOhlc } from "@constants/defiLens"
import { encrypt, getVisitorId } from "@utils/index"
import { fetchApi, fetchApiAuth } from "./fetchApi"
import { SortDescriptor } from "@nextui-org/react"
// import { getColumnKeys } from "@utils/defiLens"

const baseURL = import.meta.env.VITE_APP_BASE_DEFI_LENS_URL
const baseLLMURL = import.meta.env.VITE_APP_BASE_LLM_URL
//const baseLLMURL = "http://localhost:5000"

type Params = {
  offset?: number
  size?: number
  keyword?: string | null
  columns?: string
  [key: string]: any
  sortDescriptor?: SortDescriptor;
}

export const postRangeByKeys = async (data: Array<string>) => {
  const config = {
    baseURL,
    url: "/range_by_key",
    method: "POST",
    data: {
      keys: data,
    },
  }
  const res = await fetchApi.request(config)
  return res?.data
}

export const postQueryLens = async (data: any) => {
  const config = {
    baseURL,
    url: "/query",
    method: "POST",
    data,
  }
  const res = await fetchApi.request(config)
  return res?.data
}

export const searchTokens = async (params: Params) => {
  const keyword = params?.keyword
  let direction =
    params?.sortDescriptor?.direction === "descending" ? "desc" : null
  direction = params?.sortDescriptor?.direction === "ascending" ? "asc" : null
  const sortObject = {
    ...params.sortDescriptor,
    direction,
  }
  const newParams = {
    ...params,
    sortDescriptor: sortObject,
    keyword: keyword === "" ? null : keyword,
    columns:
      params.columns ||
      "market_cap,current_price,total_volume,price_change_percentage_24h,lunar_social_score",
  }

  const config = {
    baseURL: baseLLMURL,
    url: "/listings",
    method: "GET",
    params: newParams,
  }
  const res = await fetchApiAuth.request(config)
  return res?.data
}

export const searchCoinList = async (keyword: string | null) => {
  const config = {
    baseURL: baseLLMURL,
    url: `/listings?keyword=${keyword}`,
  }
  const res = await fetchApiAuth.request(config)
  return res?.data
}

export const postSaveLensset = async ({
  typeRanking = "listing",
  name,
  data,
}: {
  typeRanking?: string
  name: string
  data: any
}) => {
  const config = {
    baseURL: baseLLMURL,
    url: `/lens/${typeRanking}`,
    method: "POST",
    data: {
      name,
      data,
    },
  }
  const res = await fetchApiAuth.request(config)
  return res?.data
}

export const getSavedLensset = async ({
  typeRanking = "listing",
  offset = 0,
  size = 100,
}: {
  typeRanking?: string
  offset?: number
  size?: number
}) => {
  const config = {
    baseURL: baseLLMURL,
    url: `/lens/${typeRanking}`,
    method: "GET",
    params: {
      offset,
      size,
    },
  }
  const res = await fetchApiAuth.request(config)
  return res?.data?.data
}

export const deleteLenssetById = async ({
  typeRanking = "listing",
  lenssetId,
}: {
  typeRanking?: string
  lenssetId: string
}) => {
  const config = {
    baseURL: baseLLMURL,
    url: `/lens/${typeRanking}/${lenssetId}`,
    method: "DELETE",
  }
  const res = await fetchApiAuth.request(config)
  return res?.data?.data
}

export const getRangesLenKeys = async (lenKeys: string) => {
  const config = {
    baseURL: baseLLMURL,
    url: "/lens/ranges",
    method: "GET",
    params: {
      lenKeys,
    },
  }
  const res = await fetchApiAuth.request(config)
  return res?.data?.data
}

export const getTokenDetail = async (tokenId: string) => {
  const config = {
    baseURL: baseLLMURL,
    url: `listings/${tokenId}`,
    method: "GET",
  }
  const res = await fetchApiAuth.request(config)
  return res?.data
}
export const postAuthLogin = async (data: {
  auth0Token: string
  referredByCode?: string | null
}) => {
  const deviceId = await getVisitorId()
  const newData = {
    ...data,
    deviceId,
  }
  const message = encrypt(JSON.stringify(newData))
  const config = {
    baseURL: baseLLMURL,
    url: "/auth/login",
    method: "POST",
    data: {
      xLoginToken: message,
    },
  }
  const res = await fetchApi.request(config)
  return res?.data
}

export const searchPool = async (params: Params) => {
  const keyword = params?.keyword
  let direction =
    params?.sortDescriptor?.direction === "descending" ? "desc" : null
  direction = params?.sortDescriptor?.direction === "ascending" ? "asc" : null
  const sortObject = {
    ...params.sortDescriptor,
    direction,
  }
  const newParams = {
    ...params,
    sortDescriptor: sortObject,
    keyword: keyword === "" ? null : keyword,
  }
  const config = {
    baseURL: baseLLMURL,
    url: "/pools",
    method: "GET",
    params: newParams,
  }
  const res = await fetchApiAuth.request(config)
  return res?.data
}

export const getCandleInfo = async (params: {
  type: ChartTypeDataOhlc
  ohlcDataList: any
}) => {
  const config = {
    baseURL: baseLLMURL,
    url: "/charts/candle-info",
    method: "GET",
    params,
  }
  const res = await fetchApiAuth.request(config)
  return res?.data
}

export const getCandleListInfo = async (params: { ohlcDataList: any }) => {
  const config = {
    baseURL: baseLLMURL,
    url: "/charts/candle-list-info",
    method: "POST",
    data: params,
  }

  const res = await fetchApiAuth.request(config)
  return res?.data
}

export const getMarketChanges = async (listingId: string, offset: number = 0, size: number = 20) => {
  const config = {
    baseURL: baseLLMURL,
    url: `listings/market/${listingId}`,
    method: "GET",
    params: {
      category: "spot",
      offset,
      size,
      sort: JSON.stringify({ volumeUsd: -1 }),
    },
  }
  const res = await fetchApiAuth.request(config)
  return res?.data
}