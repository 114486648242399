import {
  COLUMNS_POOLS,
  COLUMNS_TOKENS,
  TypesRanking,
} from "@constants/defiLens"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

const { TOKEN, POOL } = TypesRanking

interface ColumnsCustomState {
  [TOKEN]: any[]
  [POOL]: any[]
}

const initialState: ColumnsCustomState = {
  [TOKEN]: COLUMNS_TOKENS,
  [POOL]: COLUMNS_POOLS,
}

const ColumnsCustomSlice = createSlice({
  name: "columns-custom",
  initialState,
  reducers: {
    updateColumns(state, action: PayloadAction<ColumnsCustomState>) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { updateColumns } = ColumnsCustomSlice.actions

export default ColumnsCustomSlice.reducer
