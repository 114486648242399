import { TypesRanking } from "@constants/defiLens"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

const { TOKEN, POOL } = TypesRanking

interface LensKeysState {
  [TOKEN]?: any
  [POOL]?: any
}

const initialState: LensKeysState = {
  [TOKEN]: {},
  [POOL]: {},
}

const LensKeysSlice = createSlice({
  name: "lens-keys",
  initialState,
  reducers: {
    updateLensKeys(state, action: PayloadAction<LensKeysState>) {
      return {
        ...state,
        ...action.payload,
      }
    },
  },
})

export const { updateLensKeys } = LensKeysSlice.actions

export default LensKeysSlice.reducer
