export const PATHS_NAME = {
  HOME: "/",
  LLM: "/llm",
  DEFI_LENS: "/",
  DEFI_LENS_TOKEN_RANKING: "/token-search",
  DEFI_LENS_POOL_RANKING: "/pool-ranking",
  DEFI_LENS_QUICK_TRADE: "/quick-trade",
  DEFI_LENS_UPCOMING_ICO: "/upcoming-ico",
  DEFI_LENS_ACTIVE_ICO: "/active-ico",
  DEFI_LENS_ALERT: "/alerts",
  DEFI_LENS_HISTORY_ALERT: "/history-alert",
  DEFI_LENS_BACK_TEST: "/back-test",
  DEFI_LENS_AI_POSITIONS: "/ai-positions",
  DEFI_LENS_MOCK_TRADING: "/mock-trading",
  DEFI_LENS_INDEX: "/index-price",
}

export const MIX_PANEL_TRACK_EVENT = {
  ADD_YOUR_LENS: "Add_Your_Lens",
  AI_MODE_OFF: "AI_Mode_Off",
  AI_MODE_ON: "AI_Mode_On",
  ADD_MORE_TASTE_ON: "Add_More_Taste_On",
  ADD_MORE_TASTE_OFF: "Add_More_Taste_Off",
  SAVE_LENS: "Save_Lens",
  SELECT_TOKEN_RANKING: "Select_Token_Ranking",
  SELECT_POOL_RANKING: "Select_Pool_Ranking",
  SELECT_UPCOMING_ICO_IDO: "Select_Upcoming_Ico_Ido",
  SELECT_QUICK_TRADE: "Select_Quick_Trade",
  SELECT_BACK_TEST: "Select_Back_Test",
  TOKEN_VIEW_DETAIL: "Token_View_Detail",
  VIEW_DETAIL_INFO: "View_Detail_Info",
  VIEW_DETAIL_CHART: "View_Detail_Chart",
  VIEW_DETAIL_LENS: "View_Detail_Lens",
  VIEW_DETAIL_RECIPES: "View_Detail_Recipes",
  WATCHLIST_ON: "Watch_List_On",
  WATCHLIST_OFF: "Watch_List_Off",
  ADD_TOKEN_TO_WATCHLIST: "Add_Token_To_WatchList",
  SELECT_CUSTOM_COLUMNS: "Select_Custom_Columns",
  CONFIRM_ADD_YOUR_LENS: "Confirm_Add_Your_Lens",
  COPY_REFERRAL_LINK: "Copy_Referral_Link",
  ADD_MORE_TASTE: "Add_More_Taste",

  CLICK_ALERT_HEADER: "Click_Alert_Header",
  CLICK_ICON_NOTIFICATION: "Click_Icon_Notification",
  CLICK_CONFIRM_SET_ALERT: "Click_Confirm_Set_Alert",
  CLICK_SET_ALERT: "Click_Set_Alert",
  CHOOSE_ALERT_METRIC: "Choose_Alert_Metric",
  CLICK_SET_ALERT_IN_COIN_INFO: "Click_Set_Alert_In_Coin_Info",
  SELECT_AI_POSITION: "Select_AI_Position",

  CLICK_AI_POSITIONS: "Click_AI_Positions",
  CLICK_MY_MOCK_TRADING: "Click_My_Mock_Trading",
  SELECT_SWING_TRADING: "Select_Swing_Trading",
  SELECT_POSITION_TRADING: "Select_Position_Trading",
  VIEW_BACKTEST: "View_Backtest",
  ADD_MOCK_TRADING: "Add_Mock_Trading",
  SELECT_TRADING_STYLE: "Select_Trading_Style",
  NOTI_AI_POSITIONS: "Noti_Ai_Postions",
  SHOW_AI_EXPLAINER: "Show_AI_Explainer",

  CLICK_SEARCH_LANDING_PAGE: "Click_Search_Landing_Page",
  CLICK_POSITIONS_LANDING_PAGE: "Click_Positions_Landing_Page",
  CLICK_SIMILAR_LANDING_PAGE: "Click_Similar_Landing_Page",
  CLICK_AI_EXPLAINER_LANDING_PAGE: "Click_Ai_Explainer_Landing_Page",

  TOGGLE_AI_EXPLAINER_TOKEN_DETAIL_ON: "Toggle_Ai_Explainer_Token_Detail",
  TOGGLE_SIMILAR_CHART_TOKEN_DETAIL_ON: "Toggle_Similar_Chart_Token_Detail",
}

export const SOCKET_EVENT_NAME = {
  LISTING_ALERT: "listing-alert",
  BINANCE_MARKET_PRICE: "binance-market-price",
}
