// import { xLogo } from "@assets/images"

export enum ChartTypeDataOhlc {
  SINGE = "single",
  DOUBLE = "double",
  TRIPPLE = "tripple",
}

export enum TypesRanking {
  TOKEN = "token",
  POOL = "pool",
}

export enum TokenLenssetTypes {
  MARKET = "market",
  SOCIAL = "social",
  TREND = "trend",
  MOMEMTUM = "momemtum",
  PATTERN = "pattern",
}

export enum PoolLenssetTypes {
  POOL = "pool",
}

export enum THEME {
  DARK = "dark",
  LIGHT = "light",
}

const { MARKET, SOCIAL, TREND, MOMEMTUM, PATTERN } = TokenLenssetTypes
const { POOL } = PoolLenssetTypes

export const MARKET_LENS = [
  {
    name: "Price",
    key: "current_price",
    enabled: true,
    minLevel: "Low",
    maxLevel: "High",
    unit: "$",
    desrc:
      "Current price of a cryptocurrency aggregated across exchanges and markets",
    type: MARKET,
  },
  {
    name: "Market Cap",
    key: "market_cap",
    enabled: true,
    minLevel: "Low",
    maxLevel: "High",
    unit: "$",
    desrc:
      "Market Cap = Current Price x Circulating Supply. Total market value of a cryptocurrency's circulating supply",
    type: MARKET,
  },
  {
    name: "Volatility",
    key: "lunar_volatility",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "change rate by time",
    desrc:
      "A measure of how much the price has moved up or down over short time periods.",
    type: MARKET,
  },
  {
    name: "Fully Diluted Market Cap",
    key: "fully_diluted_valuation",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "$",
    desrc:
      "Fully Diluted Market Cap = Current Price x Total Supply. Theoretical total market value of a cryptocurrency's total supply",
    type: MARKET,
  },
  {
    name: "24h Volume",
    key: "total_volume",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "$",
    desrc:
      "Total sum of actual trades taking place across all tracked platforms in the last 24 hours",
    type: MARKET,
  },
  {
    name: "24h Price Change",
    key: "price_change_percentage_24h",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: "Percentage change in price in the last 24 hours",
    type: MARKET,
  },
  {
    name: "24h MC Change",
    key: "market_cap_change_percentage_24h",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: "Percentage change in market cap in the last 24 hours",
    type: MARKET,
  },
  {
    name: "1w Price Change",
    key: "lunar_percent_change_week",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: "Percentage change in price over past week",
    type: MARKET,
  },
  {
    name: "Market Dominance",
    key: "lunar_market_dominance",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc:
      "Ratio between the market cap of a cryptocurrency and of all other cryptocurrencies",
    type: MARKET,
  },
  {
    name: "1h Exchange",
    key: "lunar_exchange_1h",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "$",
    desrc: "Total trading value on exchanges in the past hour",
    type: MARKET,
  },
  {
    name: "24h Exchange",
    key: "lunar_exchange_24h",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "$",
    desrc: "Total trading value on exchanges in the past 24 hours",
    type: MARKET,
  },
  // {
  //   name: "CEX Exchange",
  //   key: "cex_exchange",
  //   enabled: false,
  //   minLevel: "Low",
  //   maxLevel: "High",
  //   unit: "Count",
  //   desrc: "Cex exchange listed",
  //   type: MARKET,
  //   lensType: 'select'
  // },
  {
    name: "Categories",
    key: "categories",
    enabled: true,
    placeholder: "Select categories",
    type: MARKET,
    rangeType: "select",
    selectList: [
      null,
      "SHA-256D",
      "MetisDAO Ecosystem",
      "ERC404",
      "Omni",
      "Oracles",
      "EUR Stablecoin",
      "Doge Chain Ecosystem",
      "Fenbushi Capital Portfolio",
      "Liquid Staking Derivatives",
      "Treasure (MAGIC) Ecosystem ",
      "Osmosis Ecosystem",
      "DAO Maker",
      "USV Portfolio",
      "Harmony Ecosystem",
      "Tokenized Gold",
      "Fashion",
      "IOST",
      "Kadena Ecosystem",
      "CMS Holdings Portfolio",
      "X1 Ecosystem",
      "MultiversX Ecosystem",
      "Energy",
      "Mobile Payment",
      "Asset-Backed Token",
      "DragonFly Capital Portfolio",
      "Substrate",
      "Filesharing",
      "Smart Contracts",
      "Health",
      "SolRazr",
      "Moonriver Ecosystem",
      "Derivatives",
      "Toncoin Ecosystem",
      "Hybrid - dPoW & PoW",
      "Linea Ecosystem",
      "Discount Token",
      "Parallelized EVM",
      "Standard Crypto Portfolio",
      "Polychain Capital Portfolio",
      "Alameda Research Portfolio",
      "Pantera Capital Portfolio",
      "Storage",
      "X15",
      "SHA-256",
      "Restaking",
      "Sidechain",
      "Velas Ecosystem",
      "Gambling",
      "Equihash",
      "Internet Computer Ecosystem",
      "Shima Capital",
      "Ferrum Network",
      "Animal Racing",
      "Stablecoin",
      "IOU",
      "Filecoin Ecosystem",
      "Memes",
      "Ethereum Ecosystem",
      "Moon Knight Labs",
      "Trustswap Launchpad",
      "Icetea Labs",
      "Algorand Ecosystem",
      "Pulsechain Ecosystem",
      "Crowdfunding",
      "E-commerce",
      "Atomic Swaps",
      "Token",
      "QuBit",
      "Magnus Capital Portfolio",
      "FTX Bankruptcy Estate ",
      "Marketplace",
      "BlueZilla",
      "Blake2S",
      "Reputation",
      "Retail",
      "XDC Ecosystem",
      "ParaFi Capital",
      "NeoScrypt",
      "Masternodes",
      "Mimblewimble",
      "Decentralized Exchange (DEX) Token",
      "BullPerks Launchpad",
      "Centralized Exchange (CEX) Token",
      "a16z Portfolio",
      "Move To Earn",
      "DeFi",
      "Kenetic Capital Portfolio",
      "Seigniorage",
      "Cross-Chain",
      "DEX",
      "Education",
      "CMC Crypto Awards 2024",
      "Reddit Points",
      "DN404",
      "Discord Bots",
      "AI & Big Data",
      "Optimism Ecosystem",
      "Media",
      "BNB Smart Chain",
      "Data Provenance",
      "Sui Ecosystem",
      "Aptos Ecosystem",
      "Cronos Ecosystem",
      "Quantum-Resistant",
      "Play To Earn",
      "1Confirmation Portfolio",
      "Manufacturing",
      "Sei Ecosystem",
      "PolkaFoundry Red Kite",
      "Distributed Computing",
      "eGirl Capital Portfolio",
      "Terra Ecosystem",
      "Friend Tech",
      "Berachain Ecosystem",
      "Identity",
      "DuckSTARTER",
      "Blockchain Capital Portfolio",
      "Canto Ecosystem",
      "EOS",
      "Video",
      "Solana Ecosystem",
      "Philanthropy",
      "PoI",
      "Oxbull",
      "PoSign",
      "Polkadot Ecosystem",
      "Farming as a Service (FaaS)",
      "Poolz Finance Portfolio",
      "Hardware",
      "PoS",
      "Store Of Value",
      "DAO",
      "Cosmos Ecosystem",
      "LPoS",
      "Waves",
      "Alleged SEC Securities",
      "Scaling",
      "Prediction Markets",
      "Olympus Pro Ecosystem",
      "Crypto.com Capital Portfolio",
      "Governance",
      "AMM",
      "Food & Beverage",
      "Commodities",
      "Chiliz",
      "Near Protocol Ecosystem",
      "Social Money",
      "Sharding",
      "Privacy",
      "LelantusMW",
      "Hedera Hashgraph Ecosystem",
      "DAG",
      "Rollups",
      "ETH 2.0 Staking",
      "Zilliqa Ecosystem",
      "Coinfund Portfolio",
      "Hospitality",
      "SHA-512",
      "Sports",
      "Adult",
      "USD Stablecoin",
      "Inscriptions",
      "X11GOST",
      "Ontology Ecosystem",
      "Tokenized Stock",
      "rPOS",
      "Exnetwork Capital Portfolio",
      "Telegram Bot",
      "IoTeX Ecosystem",
      "Camelot Launchpad",
      "Framework Ventures Portfolio",
      "Elrond Ecosystem",
      "Rollups-as-a-Service (RaaS)",
      "Quark",
      "Content Creation",
      "Platform",
      "Celsius Bankruptcy Estate",
      "Loyalty",
      "Enterprise Solutions",
      "Logistics",
      "Escrow",
      "Yield Farming",
      "Fabric Ventures Portfolio",
      "Music",
      "HECO Ecosystem",
      "Doggone Doggerel",
      "Lyra2REv2",
      "PoW",
      "Web3",
      "Options",
      "Fantom Ecosystem",
      "Mobile",
      "Binance Coin",
      "DeFi 2.0",
      "MVB",
      "Soccer",
      "Binance Chain",
      "Yield Aggregator",
      "Synthetics",
      "Spartan Group",
      "Data Availability",
      "Cardano",
      "Cybersecurity",
      "Superstarter",
      "Search Engine",
      "Payments",
      "ICP Ecosystem",
      "PoP",
      "Nomad Capital",
      "LP Tokens",
      "Paradigm Portfolio",
      "OKEx Blockdream Ventures Portfolio",
      "Ethash",
      "Metaverse",
      "RingCT",
      "M7 POW",
      "Polkastarter",
      "BRC-20",
      "DeFi Index",
      "Sharing Economy",
      "OKExChain Ecosystem",
      "Scrypt",
      "Cat-Themed",
      "Modular Blockchain",
      "Bulletproofs",
      "TRON Ecosystem",
      "Account Abstraction",
      "BoostVC Portfolio",
      "Galaxy Digital Portfolio",
      "Arbitrum Ecosystem",
      "Gaming",
      "Multicoin Capital Portfolio",
      "Crowdsourcing",
      "Winklevoss Capital Portfolio",
      "Staking",
      "Avalanche Ecosystem",
      "Polygon Ventures Portfolio",
      "Red Packets ",
      "Binance Launchpool",
      "Blake2b",
      "Agriculture",
      "Kommunitas Launchpad",
      "Farastarter",
      "Entertainment",
      "Real Estate",
      "Dagger-Hashimoto",
      "Multiple algorithms",
      "DeSci",
      "XRP",
      "DeFiance Capital Portfolio",
      "DPoS",
      "DCG Portfolio",
      "Layer 1",
      "Yearn Partnerships",
      "Base Ecosystem",
      "Binance Launchpad",
      "X11",
      "DApp",
      "yescript",
      "Asset Management",
      "Cardano Ecosystem",
      "Wrapped Tokens",
      "LedgerPrime Portfolio",
      "State Channel",
      "Tourism",
      "NIST5",
      "Stellar",
      "Gaming Guild",
      "Placeholder Ventures Portfolio",
      "Groestl",
      "Binance Labs Portfolio",
      "NFTb Launchpad",
      "PetRock Capital Portfolio",
      "Generative AI",
      "PoA",
      "Services",
      "X14",
      "Cuckoo Cycle",
      "IoT",
      "Seedify",
      "Reef Chain",
      "LaunchZone",
      "Animoca Brands Portfolio",
      "Fan Token",
      "SkyVision Capital Portfolio",
      "Arrington XRP Capital Portfolio",
      "Hybrid - PoS & LPoS",
      "Real World Assets",
      "Social Token",
      "Tezos Ecosystem",
      "Chromia Ecosystem",
      "PoS+",
      "Interoperability",
      "Circle Ventures Portfolio",
      "Cross-Chain DEX Aggregator",
      "Protocol-Owned Liquidity",
      "Luxury",
      "zkSync Era Ecosystem",
      "Hybrid - PoW & PoS",
      "Cosmos",
      "Celo Ecosystem",
      "Transport",
      "Events",
      "Polkadot",
      "Opensource",
      "Asset-Backed Stablecoin",
      "Blast Ecosystem",
      "Bitcoin Ecosystem",
      "BNB Chain",
      "Jump Crypto",
      "Electric Capital Portfolio",
      "Art",
      "VR/AR",
      "Coinbase Ventures Portfolio",
      "Lending & Borrowing",
      "Analytics",
      "Launchpad",
      "SEC Security Token",
      "Insurance",
      "POS 3.0",
      "Finance / Banking",
      "Everscale Ecosystem",
      "Three Arrows Capital Portfolio",
      "Algorithmic Stablecoin",
      "Huobi Capital Portfolio",
      "Bounce Launchpad",
      "Blake",
      "Polygon Ecosystem",
      "Layer 2",
      "Rebase",
      "Gemini Frontier Fund",
      "Marketing",
      "Jobs",
      "Communications & Social Media",
      "Wallet",
      "Medium of Exchange",
      "DWF Labs Portfolio",
      "Research",
      "Zero Knowledge Proofs",
      "Injective Ecosystem",
      "CryptoNight",
      "Mineable",
      "Hybrid - PoW & DPoS",
      "Hashkey Capital Portfolio",
      "VBC Ventures Portfolio",
      "Genpad",
      "Collectibles & NFTs",
      "Hacken Foundation",
      "Hybrid - PoW & nPoS",
      "X13",
      "Paal Ecosystem",
      "Gains Associates",
      "Software",
    ].map((val) => ({
      label: val || "All Categories",
      value: val,
      icon: "",
    })),
  },
  {
    name: "Exchanges",
    key: "exchanges",
    enabled: true,
    placeholder: "Select exchanges",
    type: MARKET,
    rangeType: "select",
    selectList: [
      null,
      "10KSwap",
      "1BCH",
      "3xcalibur",
      "9inch",
      "Aave",
      "ABCC",
      "Acala Swap",
      "ACDX",
      "Ace",
      "Acsi Finance",
      "Aerodrome (Base)",
      "Aevo",
      "Agni Finance",
      "Agora Swap",
      "Aktionariat (Ethereum)",
      "ALEX",
      "Algebra finance",
      "Alien Base",
      "AlienFi",
      "AllInXSwap (opBnb)",
      "Alpha5",
      "AltcoinTrader",
      "AlterDice",
      "Altmarkets",
      "Amaterasu Finance",
      "Animeswap",
      "ApertureSwap",
      "Apeswap (Arbitrum)",
      "ApeSwap",
      "ApeSwap (Polygon)",
      "Apeswap (Telos)",
      "ApeX Pro",
      "Aprobit",
      "AQX",
      "Flipster",
      "Arbidex",
      "Arbswap",
      "Archerswap",
      "ArthSwap",
      "Ashswap",
      "Astroport (Classic)",
      "Astroport (Injective)",
      "Astroport (Neutron)",
      "Astroport (Sei)",
      "Astroport V2",
      "Astrovault",
      "AuroraSwap",
      "AutoShark Finance",
      "AUX Exchange",
      "AYIN",
      "Azbit",
      "B2BX",
      "BabyDogeSwap",
      "BabySwap",
      "Baguette",
      "Bakeryswap",
      "BakerySwap (Base)",
      "Bakkt",
      "Balanced",
      "Balancer V2",
      "Balancer V2 (Arbitrum)",
      "Balancer V2 (Gnosis)",
      "Balancer V2 (Polygon)",
      "Balancer V1",
      "Balancer V2 (Avalanche)",
      "Balancer V2 (Base)",
      "Bancor (V2)",
      "Bancor (V3)",
      "BAPTSwap",
      "Baryon Network",
      "BaseFEX",
      "BaseSwap",
      "Baso Finance",
      "BCEX",
      "Beamswap",
      "Beam Swap",
      "Beaxy",
      "Beethoven X",
      "Beethoven X (Optimism)",
      "Benswap",
      "Bgogo",
      "Bibox",
      "Bibox (Futures)",
      "Biconomy",
      "BigONE",
      "BigONE Futures",
      "Bilaxy",
      "Binance",
      "Binance DEX",
      "Binance DEX (Mini)",
      "Binance (Futures)",
      "Binance US",
      "BinarySwap",
      "BingX",
      "BingX (Futures)",
      "BiONE",
      "Birake",
      "Bisq",
      "Biswap",
      "Biswap V3",
      "Bit2c",
      "Bit2Me",
      "Bitazza",
      "Bitbank",
      "zondacrypto",
      "Bitbegin",
      "BitBNS",
      "BITFRONT",
      "bitcastle",
      "Bitci TR",
      "FMFW.io",
      "Bitcointry Exchange",
      "BIT",
      "BIT (Futures)",
      "BitDelta",
      "BITEXBOOK",
      "Bitexen",
      "Bitexlive",
      "Bitfex",
      "Bitfinex",
      "Bitfinex (Futures)",
      "bitFlyer",
      "Bitflyer (Futures)",
      "Bitforex",
      "Bitforex (Futures)",
      "Bitget",
      "Bitget Futures",
      "BitHash",
      "Bithumb",
      "Bithumb (Futures)",
      "BitGlobal",
      "Bitinka.com",
      "BitKonan",
      "Bitkub",
      "Bitlo",
      "BitMake",
      "BitMake Futures",
      "BitMart",
      "Bitmart Futures",
      "AscendEX (BitMax)",
      "AscendEX  (BitMax) (Futures)",
      "BitMEX (Derivative)",
      "BitMEX",
      "BitOnBay",
      "BitoPro",
      "One Trading",
      "Bitrue",
      "Bitrue (Futures)",
      "Bitso",
      "Bitstamp",
      "Bitsten",
      "BitStorage",
      "Bittime",
      "Bittrex Global",
      "Bitubu Exchange",
      "Bitunix",
      "Bitunix Futures",
      "Bitvavo",
      "BitVenus Futures",
      "BitVenus",
      "BitZ (Futures)",
      "BKEX",
      "Blazeswap (Songbird)",
      "BlazeSwap (Flare) ",
      "Blockchain.com",
      "Blofin",
      "BlueMove",
      "Blueshift",
      "BobSwap (Polygon)",
      "BossSwap",
      "BTC-Alpha",
      "BTCBOX",
      "BTCC",
      "BTCC Futures",
      "BTCMarkets",
      "BTCMEX",
      "BTCSquare",
      "BTC Trade UA",
      "BtcTurk | Kripto",
      "BTSE",
      "BTSE (Futures)",
      "Bullish",
      "BuyUcoin",
      "BW.com",
      "Bybit (Futures)",
      "Bybit",
      "BYDFi",
      "Byte Exchange",
      "Bytex",
      "Camelot",
      "Camelot V3",
      "Canto Dex",
      "Cantoswap",
      "Capricorn",
      "Carbon",
      "Catex",
      "CaviarNine",
      "Cetus",
      "CEX.IO",
      "ChainEX",
      "Changelly PRO",
      "CherrySwap",
      "ChilizX",
      "Chronos",
      "CitadelSwap",
      "CITEX",
      "Claimswap",
      "Clipper (Arbitrum)",
      "Clipper (Ethereum)",
      "Clipper (Optimism)",
      "Clipper (Polygon)",
      "CME Group",
      "Coinbase International Exchange",
      "Coinbase International Exchange (Derivatives)",
      "CoinCatch",
      "CoinCatch Derivatives",
      "Coincheck",
      "CoinDCX",
      "Coindeal",
      "CoinEgg",
      "CoinEx",
      "CoinEx (Futures)",
      "Coinfalcon",
      "Coinfield",
      "CoinFLEX",
      "CoinFLEX (Futures)",
      "Coingi",
      "CoinJar Exchange",
      "Coinlist",
      "CoinMargin",
      "Coinmetro",
      "Coinone",
      "Coinsbit",
      "Coins.ph",
      "Coinstore",
      "CoinTiger",
      "CoinTiger (Futures)",
      "CoinTR Pro",
      "CoinTR Pro (Derivatives)",
      "CoinW",
      "Coinzix",
      "Coinzoom",
      "ComethSwap",
      "Concave",
      "C-Patex",
      "Crema Finance",
      "Crescent",
      "CREX24",
      "Crodex",
      "Cronaswap",
      "Cronus Finance",
      "CroSwap",
      "CrowdSwap (Polygon)",
      "Cryptal",
      "Crypto.com Exchange",
      "Crypto.com Exchange (Futures)",
      "Cryptology",
      "cSwap",
      "C-Trade",
      "CUBISwap",
      "Currency.com",
      "Curve (Arbitrum)",
      "Curve (Avalanche)",
      "Curve (Celo)",
      "Curve (Ethereum)",
      "Curve (Fantom)",
      "Curve (Moonbeam)",
      "Curve (Optimism)",
      "Curve (Polygon)",
      "Curve (Xdai)",
      "DackieSwap V3",
      "DackieSwap V2",
      "DAO Swap",
      "Dark KnightSwap",
      "Dcoin",
      "DeDust",
      "Deepcoin",
      "Deepcoin (Derivatives)",
      "DeFiChain DEX",
      "Defi Kingdoms",
      "Defi Kingdoms (Crystalvale)",
      "DefiPlaza",
      "DefiPlaza (Radix)",
      "DeFi Swap",
      "DeGate",
      "Delta Exchange (Futures)",
      "Delta Exchange",
      "Demex",
      "Demex (Derivatives)",
      "Deribit",
      "Deribit Spot",
      "DerpDEX (zkSync)",
      "DerpDEX (Base)",
      "DerpDEX (opBnb)",
      "Rhino.fi",
      "Dexalot",
      "Dex-Trade",
      "dForceswap (Arbitrum)",
      "dForceswap (BSC)",
      "dForceswap (Ethereum)",
      "dForceswap (Optimism)",
      "dForceswap (Polygon)",
      "DFX",
      "DFX (Polygon)",
      "Dfyn",
      "Diffusion Finance",
      "DIFX",
      "DigiFinex",
      "Digitalexchange.id",
      "DinosaurEggs (BSC)",
      "DODO (Ethereum)",
      "DODO (Arbitrum)",
      "DODO (Avalanche)",
      "DODO (BSC)",
      "DODO (Polygon)",
      "Dogeshrek",
      "DogeSwap",
      "DogSwap",
      "DOOAR (BSC)",
      "DOOAR (Ethereum)",
      "DoveSwap V3",
      "BTX",
      "Dracula Finance",
      "Drift Protocol",
      "DuckyDeFi",
      "DueDEX",
      "dYdX Perpetual",
      "Dystopia",
      "EchoDEX",
      "Elk Finance (Avalanche)",
      "Elk Finance (BSC)",
      "Elk Finance (Ethereum)",
      "Elk Finance (Polygon)",
      "Elk Finance (Telos)",
      "Ellipsis Finance",
      "Emirex",
      "EmpireDEX (Cronos)",
      "EmpireDEX (BSC)",
      "EmpireDEX (Empire)",
      "Energiswap",
      "Enosys (Flare)",
      "Equalizer",
      "Equalizer (Base)",
      "Équilibre",
      "EvmoSwap",
      "Excalibur",
      "EXMO",
      "eZKalibur",
      "Fairdesk",
      "Fairdesk Derivatives",
      "Fairyswap",
      "FameEX",
      "Fastex",
      "FatBTC",
      "Ferro Protocol",
      "FinanceX",
      "FinexBox",
      "Firebird Finance (Polygon)",
      "FlatQube",
      "FlowX Finance",
      "Flybit",
      "FMCPAY",
      "Forge",
      "Forteswap",
      "4swap",
      "Foxbit",
      "Fraxswap (Ethereum)",
      "Freiexchange",
      "FUBT",
      "FusionX V2",
      "FusionX V3",
      "FuzzSwap",
      "Fx Swap",
      "Gate.io",
      "Gate.io (Futures)",
      "GDAC",
      "Coinbase Exchange",
      "Gemini",
      "Gemini Derivatives",
      "GemSwap",
      "Glide Finance",
      "Globe",
      "Globe (Derivatives)",
      "Globiance",
      "GMO Japan",
      "GMO Japan (Futures)",
      "GMX Derivatives (Arbitrum)",
      "GMX Derivatives (Avalanche)",
      "GoPax",
      "Graviex",
      "Gravity Finance",
      "Greenhouse",
      "GumBall",
      "HakuSwap",
      "Halo Trade",
      "Hanbitco",
      "HashKey Exchange",
      "BHEX (Futures)",
      "Hebeswap",
      "HeliSwap",
      "Hermes Protocol",
      "HitBTC",
      "HitBTC (Derivatives)",
      "Holdstation DeFutures",
      "Honeyswap",
      "Honeyswap (Polygon)",
      "HopeSwap",
      "Hopex",
      "HorizonDEX",
      "Hotcoin Global",
      "Huckleberry",
      "HTX",
      "HTX Futures",
      "BitTrade",
      "HTX Korea",
      "Hydra DEX",
      "HydraDX",
      "Hyperliquid",
      "IcecreamSwap (Core)",
      "Icrypex",
      "Idex",
      "Impossible Finance",
      "Impossible Finance (Humanode)",
      "Impossible Finance (v3)",
      "Increment Swap",
      "Independent Reserve",
      "Indodax",
      "Helix",
      "Helix (Futures)",
      "Integral SIZE",
      "INX One",
      "itBit",
      "iZiSwap (BSC)",
      "iZiSwap (Linea)",
      "iZiSwap (Manta Pacific)",
      "iZiSwap (Mantle)",
      "iZiSwap (Scroll)",
      "iZiSwap (zkSync)",
      "JediSwap",
      "JetSwap",
      "Binance JEX (Futures)",
      "Jswap",
      "Julswap",
      "Junoswap",
      "Justmoney (Bittorent)",
      "eckoDEX",
      "Kaidex",
      "Kaidex V3",
      "Kanga",
      "Karura Swap",
      "Katana",
      "Kava Swap",
      "KDSwap",
      "Khaos Exchange",
      "KibbleSwap",
      "KickEX",
      "Kine Protocol (Derivatives)",
      "Kine Protocol (Spot)",
      "Kinesis Money",
      "Kinetix V3",
      "KLAYSwap",
      "Klayswap V3",
      "Bitcoin.me",
      "KLEX",
      "KnightSwap",
      "Koinbx",
      "Komodo Wallet",
      "Korbit",
      "Kraken",
      "Kraken (Futures)",
      "KriyaDEX",
      "KTX.Finance (BSC)",
      "KTX.Finance (Mantle)",
      "KuCoin",
      "Kujira Fin",
      "KuCoin Futures",
      "Kuna Exchange",
      "Kuswap",
      "Kwenta Derivatives",
      "Kyberswap Classic (Arbitrum)",
      "KyberSwap Classic (Avalanche)",
      "KyberSwap Classic (BSC)",
      "Kyberswap Classic (Bittorent)",
      "KyberSwap Classic (Ethereum)",
      "KyberSwap Classic (Fantom)",
      "Kyberswap Classic (Optimism)",
      "KyberSwap Classic (Polygon)",
      "KyberSwap Elastic (Ethereum)",
      "Kyberswap Elastic (Arbitrum)",
      "Kyberswap Elastic (Avalanche)",
      "Kyberswap Elastic (BSC)",
      "Kyberswap Elastic (Fantom)",
      "Kyberswap Elastic (Linea)",
      "Kyberswap Elastic (Optimism)",
      "Kyberswap Elastic (Polygon)",
      "LATOKEN",
      "LBank",
      "LCX Exchange",
      "LeetSwap (Base)",
      "LeetSwap (Linea)",
      "LeonicornSwap",
      "Levana Perps (Osmosis)",
      "Levinswap (xDai)",
      "LFGswap",
      "LFGSwap (Core)",
      "Libre Swap",
      "LIF3 (Tombchain)",
      "LIF3 (BSC)",
      "LIF3 (Polygon)",
      "Liquid Perpetuals",
      "Liquidswap",
      "LocalTrade",
      "Loop Markets",
      "Loopring",
      "Loopring AMM",
      "Luaswap",
      "LuigiSwap",
      "Luno",
      "Lydia Finance",
      "Lykke",
      "Lynex",
      "Magicswap",
      "xExchange",
      "Makiswap",
      "Mangata",
      "Mango Markets (Derivatives)",
      "Mango Markets",
      "MantaSwap",
      "Mars Ecosystem",
      "MARSWAP",
      "Maverick Protocol",
      "Maverick Protocol (Base)",
      "Maverick Protocol (BSC)",
      "Maverick Protocol (zkSync)",
      "Max Maicoin",
      "MCDEX (Arbitrum)",
      "MCDEX (BSC)",
      "Mdex",
      "Mdex BSC",
      "Megaton Finance",
      "MelegaSwap",
      "Mercado Bitcoin",
      "Mercatox",
      "Meshswap",
      "Metal X",
      "MiaSwap",
      "MilkySwap",
      "Mimo",
      "MIND Games",
      "Minswap",
      "Mistswap",
      "MMFinance (Cronos)",
      "MM Finance (Arbitrum)",
      "MMFinance (Polygon)",
      "MMFinance V3 (Arbitrum)",
      "MojitoSwap",
      "Moraswap V2",
      "Morpheus Swap",
      "Mudrex",
      "Muesliswap",
      "Muesliswap (Milkada)",
      "MUFEX",
      "Mute",
      "MEXC",
      "MEXC (Futures)",
      "MCS",
      "NachoSwap",
      "Namebase",
      "Nami.Exchange",
      "Nanu Exchange",
      "Narkasa",
      "Nash",
      "NearPAD",
      "Netswap",
      "Neutroswap",
      "Newdex",
      "Nexus Mutual",
      "NiceHash",
      "Nominex",
      "Nomiswap",
      "Nomiswap (Stable)",
      "Nonkyc.io",
      "NovaDAX",
      "OasisSwap (Base)",
      "OasisDEX",
      "OccamX",
      "Oceanex",
      "Okcoin",
      "OKX",
      "OKX (Futures)",
      "Omgfin",
      "OmniDex",
      "OneDex",
      "1inch Liquidity Protocol",
      "1inch Liquidity Protocol (BSC)",
      "Onsen Swap",
      "Oolongswap",
      "OpenBook",
      "OpenLeverage",
      "OpenOcean",
      "OpenSwap",
      "OPNX Derivatives",
      "OPNX",
      "Oracleswap",
      "OraiDEX",
      "OrangeX",
      "OrangeX Futures",
      "Orca",
      "Orderbook.io",
      "Orderly Network",
      "Orderly Network (Derivatives)",
      "OreoSwap",
      "Osmosis",
      "Ovex",
      "P2B",
      "Pablo",
      "PacificSwap",
      "Paintswap",
      "Pancakeswap (Aptos)",
      "PancakeSwap (Ethereum)",
      "PancakeSwap (v2)",
      "Pancakeswap (Stableswap)",
      "Pancakeswap V2 (Arbitrum)",
      "Pancakeswap V2 (opBnb)",
      "Pancakeswap V2 (Polygon zkEVM)",
      "PancakeSwap (zkSync)",
      "Pancakeswap V3 (Arbitrum)",
      "Pancakeswap V3 (Base)",
      "Pancakeswap V3 (BSC)",
      "Pancakeswap V3 (Ethereum)",
      "Pancakeswap V3 (Linea)",
      "Pancakeswap V3 (opBnb)",
      "Pancakeswap V3 (Polygon zkEVM)",
      "Pancakeswap V3 (zkSync)",
      "Pangolin",
      "Pangolin (Flare)",
      "Pangolin (Songbird)",
      "Paradex",
      "Paribu",
      "Paritex",
      "Paymium",
      "PearlFi",
      "PearlFi V1.5",
      "Pegasys",
      "Pegasys V3 (Rollux)",
      "Perpetual Protocol",
      "Phemex",
      "Phemex (Futures)",
      "PhotonSwap (Cronos)",
      "PhotonSwap (Kava)",
      "Phux",
      "PinkSwap",
      "Pionex",
      "Planet Finance",
      "Platypus Finance",
      "Plenty Network",
      "PointPay",
      "Polaris",
      "PolkaEx (Shiden)",
      "Polkaswap",
      "Poloniex",
      "Poloniex Futures",
      "Polycat Finance",
      "PolyZap",
      "POMSwap",
      "Powertrade",
      "Powertrade (Derivatives)",
      "Powswap",
      "Prime XBT",
      "Prism Protocol",
      "ProBit Global",
      "Probit (Korea)",
      "ProtoFi",
      "PuddingSwap",
      "PulseX",
      "PulseX V2",
      "Punkswap",
      "Purcow",
      "QMall",
      "Quickswap",
      "Quickswap (Dogechain)",
      "Quickswap (Polygon zkEVM)",
      "Quickswap (v3)",
      "Quickswap V3 (Manta Pacific)",
      "Quipuswap",
      "Liquid",
      "RabbitX",
      "RadioShack (Avalanche)",
      "RadioShack (BSC)",
      "RadioShack (Ethereum)",
      "RadioShack (Polygon)",
      "Ramses",
      "Ramses V2",
      "Raydium",
      "RCP Swap",
      "Ref Finance",
      "Resfinex",
      "Retro",
      "Rocketswap",
      "Rockswap",
      "Ruby Exchange",
      "Saber",
      "SafeTrade",
      "SaitaSwap (BSC)",
      "SaitaSwap (Ethereum)",
      "SakeSwap",
      "Saros Finance",
      "Saucerswap V1",
      "Saucerswap V2",
      "ScrollSwap",
      "SecondBTC",
      "SecretSwap",
      "Serum DEX",
      "Shade Protocol",
      "ShadowSwap",
      "SharkSwap",
      "SharkySwap",
      "Shibaswap",
      "ShibSwap",
      "ShimmerSea",
      "Siennaswap",
      "Sifchain",
      "SINEGY",
      "Skydrome",
      "SmarDex (Arbitrum)",
      "SmarDex (Base)",
      "SmarDex (BSC)",
      "SmarDex",
      "SmarDex (Polygon)",
      "Sobal (Neon EVM)",
      "Solarbeam",
      "Solarflare",
      "SolidLizard",
      "Solidly V1 (Fantom)",
      "Solidly V2 (Ethereum)",
      "Solidly V3 (Ethereum)",
      "Solidly V3 (Fantom)",
      "SoliSnek",
      "Sologenic",
      "Sonic",
      "Soswap",
      "Soulswap",
      "SouthXchange",
      "Sovryn DEX",
      "Soy Finance (Callisto)",
      "SpaceFi",
      "SpaceFi (ZkSync)",
      "SpartaDEX",
      "Spartan Protocol",
      "Spectrum Finance",
      "Spectrum Finance (Cardano)",
      "Sphynx (Brise)",
      "Sphynx Swap (BSC)",
      "Spice Trade (Avalanche)",
      "Spicyswap",
      "SpinaqDex",
      "SpiritSwap",
      "SpiritSwap (V2)",
      "SpookySwap",
      "StakeCube Exchange",
      "StellarTerm",
      "StellaSwap",
      "Stellaswap (V3)",
      "Step Exchange",
      "Step Finance",
      "Sterling",
      "STEX",
      "STON.fi",
      "Stormgain",
      "Stormgain Futures",
      "Suiswap",
      "Sundaeswap",
      "SUN.io",
      "Surfswap",
      "Sushiswap",
      "Sushiswap (Arbitrum One)",
      "Sushiswap (Arbitrum Nova)",
      "Sushiswap (Avalanche)",
      "Sushiswap (BSC)",
      "Sushiswap Celo",
      "Sushiswap (Fantom)",
      "Sushiswap (Harmony)",
      "Sushiswap (Polygon POS)",
      "Sushiswap V2 (Base)",
      "Sushiswap V3 (Arbitrum)",
      "Sushiswap V3 (Arbitrum Nova)",
      "SushiSwap V3 (Base)",
      "Sushiswap V3 (BSC)",
      "Sushiswap V3 (Ethereum)",
      "Sushiswap V3 (Fantom)",
      "Sushiswap V3 (Linea)",
      "Sushiswap V3 (Optimism)",
      "Sushiswap V3 (Polygon)",
      "Sushiswap V3 (Polygon zkEVM)",
      "Sushiswap V3 (ThunderCore)",
      "Sushiswap (xDai)",
      "SwapBased",
      "SwapFish",
      "Swappi",
      "Swapr (Arbitrum)",
      "Swapr (Ethereum)",
      "Swapr (Xdai)",
      "Swapsicle",
      "Swapsicle (Telos)",
      "Swapsicle V2 (Mantle)",
      "Swapsicle V2 (Telos)",
      "Swop.Fi",
      "SWYCH",
      "SyncSwap",
      "SyncSwap (Linea)",
      "SyncSwap (Scroll)",
      "SynFutures",
      "Kwenta",
      "Synthswap",
      "TangleSwap",
      "TangoSwap",
      "Tapbit",
      "Tarmex",
      "Orbix",
      "Tealswap",
      "TempleDAO",
      "Tenderswap",
      "Terraport",
      "Terraswap Classic",
      "Tethys Finance",
      "Tetuswap",
      "Terraformer",
      "Thala",
      "THENA",
      "THENA FUSION",
      "THENA (opBnb)",
      "TheRockTrading",
      "THORSwap",
      "Thorus",
      "THORWallet DEX",
      "Throne V3 (Base)",
      "Tidex",
      "Tinyman",
      "Tokenize",
      "Tokenlon",
      "Tokenomy",
      "TokenSets",
      "TokoCrypto",
      "TOKOK",
      "Tokpie",
      "Tomb Swap (Fantom)",
      "Toobit",
      "Toobit Futures",
      "TradeOgre",
      "Trader Joe",
      "Trader Joe V2.1 (Arbitrum)",
      "Trader Joe V2.1 (Avalanche)",
      "Trader Joe V2.1 (BSC)",
      "Trader Joe v2 (Arbitrum)",
      "Trader Joe v2",
      "Trader Joe V2 (BSC)",
      "Tranquil Finance",
      "Trisolaris",
      "Tropical Finance",
      "Trubit",
      "Trustless Markets",
      "Turbos Finance",
      "Txbit",
      "Ubeswap",
      "Unicly",
      "UniSat",
      "Uniswap V3 (BSC)",
      "Uniswap V2 (Ethereum)",
      "Uniswap V3 (Ethereum)",
      "Uniswap V3 (Arbitrum One)",
      "Uniswap V3 (Avalanche)",
      "Uniswap V3 (Base)",
      "Uniswap V3 (Celo)",
      "Uniswap V3 (Optimism)",
      "Uniswap V3 (Polygon)",
      "UniWswap",
      "Unnamed",
      "Upbit",
      "Upbit Indonesia ",
      "USDFI",
      "VALR",
      "vSwap BSC",
      "Velocimeter",
      "Velocimeter (Base)",
      "Velocimeter (Fantom)",
      "Velocimeter V2",
      "Velocore",
      "Velocore V2 (Linea)",
      "Velocore V2 (Telos)",
      "Velocore V2 (ZkSync)",
      "Velodrome Finance",
      "Velodrome Finance v2",
      "Verse",
      "Vertex Protocol (Derivatives)",
      "Vertex Protocol (Spot)",
      "Vindax",
      "VirtuSwap",
      "ViteX",
      "Voltage Finance",
      "Voltswap (Meter)",
      "Voltswap (Theta)",
      "VVS Finance",
      "WAGMI (Ethereum)",
      "WAGMI (Kava)",
      "WAGMI (zkSync)",
      "WagyuSwap",
      "Wannaswap",
      "WanSwap",
      "WaultSwap Polygon",
      "Wavelength",
      "WX Network",
      "WazirX",
      "Websea",
      "WEMIX.Fi",
      "WhiteBIT",
      "WhiteBIT Futures",
      "White Whale (Juno)",
      "White Whale (Migaloo)",
      "Wigoswap",
      "WingRiders",
      "Wombat Exchange (BNB)",
      "Wombat (Arbitrum)",
      "WOOFi",
      "WoofSwap",
      "WOOFSwap (Core)",
      "WOO X (Futures)",
      "WOO X",
      "Xave Finance",
      "XCAD DEX",
      "XeggeX",
      "XSwap",
      "XSwap Protocol V3",
      "XT.COM",
      "XT.COM (Derivatives)",
      "YoBit",
      "Yodeswap",
      "Yokaiswap",
      "Yoshi.exchange (BSC)",
      "Yoshi.exchange (Fantom)",
      "Yunex.io",
      "Zaif",
      "Zappy",
      "ZBG",
      "ZBG Futures",
      "ZBX",
      "Zebitex",
      "ZebPay",
      "Zenlink (Astar)",
      "Zenlink (Bifrost Kusama)",
      "Zenlink (Moonbeam)",
      "Zenlink (Moonriver)",
      "0x Protocol",
      "ZigZag (zkSync v1)",
      "ZigZag (Arbitrum)",
      "ZilSwap",
      "Zipmex",
      "ZipSwap",
      "Zircon",
      "zkSwap Finance",
      "Zyberswap",
    ].map((val) => ({
      label: val || "All Exchanges",
      value: val,
      icon: "",
    })),
  },
]

export const SOCIAL_LENS = [
  {
    name: "Social Score",
    key: "lunar_social_score",
    enabled: true,
    minLevel: "Not important",
    maxLevel: "Trending",
    unit: "score",
    desrc:
      "Overall score of social influence of a cryptocurrency across social platforms",
    type: SOCIAL,
  },
  // {
  //   logoUrl: xLogo,
  //   name: "X trending",
  //   key: "lunar_tweets",
  //   enabled: false,
  //   minLevel: "Not important",
  //   maxLevel: "Trending",
  //   unit: "score",
  //   desrc: "Trending score on X",
  //   type: SOCIAL,
  // },
  // {
  //   name: "Reddit Trending",
  //   key: "lunar_reddit",
  //   enabled: false,
  //   minLevel: "Not important",
  //   maxLevel: "Trending",
  //   unit: "score",
  //   desrc: "Trending score on Reddit",
  //   type: SOCIAL,
  // },
  {
    name: "Social Volume",
    key: "lunar_social_volume",
    enabled: false,
    minLevel: "Not important",
    maxLevel: "Trending",
    unit: "count",
    desrc: "Total number of mentions across social platforms",
    type: SOCIAL,
  },
  // {
  //   logoUrl: topicTrending,
  //   name: "Topic Trending",
  //   key: "lunar_topic_delta",
  //   enabled: false,
  //   minLevel: "Not important",
  //   maxLevel: "Trending",
  //   unit: "score",
  //   desrc: "Trending topic score on social platforms",
  //   type: SOCIAL,
  // },
  // {
  //   name: "Average Sentiment",
  //   key: "lunar_average_sentiment",
  //   enabled: false,
  //   minLevel: "Most Bearish",
  //   maxLevel: "Most Bullish",
  //   unit: "score",
  //   desrc: "Overall bullishness or bearishness on social posts (from 1 to 5)",
  //   type: SOCIAL,
  // },
  // {
  //   name: "Bullish Sentiment",
  //   key: "lunar_bullish_sentiment",
  //   enabled: false,
  //   minLevel: "Most Bearish",
  //   maxLevel: "Most Bullish",
  //   unit: "score",
  //   desrc: "Bullish score on social platforms",
  //   type: SOCIAL,
  // },
  // {
  //   name: "Bearish Sentiment",
  //   key: "lunar_bearish_sentiment",
  //   enabled: false,
  //   minLevel: "Most Bearish",
  //   maxLevel: "Most Bullish",
  //   unit: "score",
  //   desrc: "Bearish score on social platforms",
  //   type: SOCIAL,
  // },
]

export const TREND_LENS = [
  {
    name: "Short Term Trend",
    key: "short_term_trend",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "time",
    desrc: "",
    type: TREND,
  },
  {
    name: "Medium Term Trend",
    key: "medium_term_trend",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "time",
    desrc: "",
    type: TREND,
  },
  {
    name: "Long Term Trend",
    key: "long_term_trend",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "time",
    desrc: "",
    type: TREND,
  },
]

export const MOMEMTUM_LENS = [
  {
    name: "RSI 7",
    key: "rsi7",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "",
    desrc: "",
    type: MOMEMTUM,
  },
  {
    name: "RSI 14",
    key: "rsi14",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "",
    desrc: "",
    type: MOMEMTUM,
  },
  {
    name: "RSI 25",
    key: "rsi25",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "",
    desrc: "",
    type: MOMEMTUM,
  },
  {
    name: "SMA 5",
    key: "sma5",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: `The lens calculates the deviation of the current price from the average price over the last [period] days (SMA).
    "Value > 0" when "Price > SMA"
    "Value < 0" when "Price < SMA"`,
    type: MOMEMTUM,
  },
  {
    name: "SMA 20",
    key: "sma20",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: `The lens calculates the deviation of the current price from the average price over the last [period] days (SMA).
    "Value > 0" when "Price > SMA"
    "Value < 0" when "Price < SMA"`,
    type: MOMEMTUM,
  },
  {
    name: "SMA 100",
    key: "sma100",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: `The lens calculates the deviation of the current price from the average price over the last [period] days (SMA).
    "Value > 0" when "Price > SMA"
    "Value < 0" when "Price < SMA"`,
    type: MOMEMTUM,
  },
  {
    name: "Lower Bollinger Band",
    key: "lower_bband",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: `The lens calculates the price deviation from the Lower Bollinger Band (LBB).
    "Value > 0" when "Price > LBB"
    "Value < 0" when "Price < LBB"`,
    type: MOMEMTUM,
  },
  {
    name: "Middle Bollinger Band",
    key: "middle_bband",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: `The lens calculates the price deviation from the Middle Bollinger Band (MBB).
    "Value > 0" when "Price > MBB"
    "Value < 0" when "Price < MBB"`,
    type: MOMEMTUM,
  },
  {
    name: "Upper Bollinger Band",
    key: "upper_bband",
    enabled: false,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: `
    The lens calculates the price deviation from the Upper Bollinger Band (UBB).
    "Value > 0" when "Price > UBB"
    "Value < 0" when "Price < UBB"`,
    type: MOMEMTUM,
  },
  {
    name: "MACD",
    key: "macd",
    enabled: false,
    placeholder: "Select MACD",
    desrc: "Watch for MACD crossing the signal line - potential trend signal",
    type: MOMEMTUM,
    rangeType: "select",
    selectList: [null, "Cross Above", "Cross Below"].map((val) => ({
      label: val || "All MACD",
      value: val,
      icon: "",
    })),
  },
]

export const PATTERN_LENS = [
  {
    name: "Head and Shoulder Pattern",
    key: "head_shoulder_pattern",
    enabled: false,
    placeholder: "Select pattern",
    type: PATTERN,
    rangeType: "select",
    selectList: [null, "Head and Shoulder", "Inverse Head and Shoulder"].map(
      (val) => ({
        label: val || "No Pattern",
        value: val,
        icon: "",
      }),
    ),
  },
  {
    name: "Multiple Top and Bottom Pattern",
    key: "multiple_top_bottom_pattern",
    enabled: false,
    placeholder: "Select pattern",
    type: PATTERN,
    rangeType: "select",
    selectList: [null, "Multiple Top", "Multiple Bottom"].map((val) => ({
      label: val || "No Pattern",
      value: val,
      icon: "",
    })),
  },
  {
    name: "Triangle Pattern",
    key: "triangle_pattern",
    enabled: false,
    placeholder: "Select pattern",
    type: PATTERN,
    rangeType: "select",
    selectList: [null, "Ascending Triangle", "Descending Triangle"].map(
      (val) => ({
        label: val || "No Pattern",
        value: val,
        icon: "",
      }),
    ),
  },
  {
    name: "Wedge Pattern",
    key: "wedge_pattern",
    enabled: false,
    placeholder: "Select pattern",
    type: PATTERN,
    rangeType: "select",
    selectList: [null, "Wedge Up", "Wedge Down"].map((val) => ({
      label: val || "No Pattern",
      value: val,
      icon: "",
    })),
  },
  {
    name: "Channel Pattern",
    key: "channel_pattern",
    enabled: false,
    placeholder: "Select pattern",
    type: PATTERN,
    rangeType: "select",
    selectList: [null, "Channel Up", "Channel Down"].map((val) => ({
      label: val || "No Pattern",
      value: val,
      icon: "",
    })),
  },
  {
    name: "Double Top Bottom Pattern",
    key: "double_pattern",
    enabled: false,
    placeholder: "Select pattern",
    type: PATTERN,
    rangeType: "select",
    selectList: [null, "Double Top", "Double Bottom"].map((val) => ({
      label: val || "No Pattern",
      value: val,
      icon: "",
    })),
  },
]

export const POOL_LENS = [
  {
    name: "Projects",
    key: "project_name",
    enabled: true,
    placeholder: "Select projects",
    type: POOL,
    rangeType: "select",
    selectList: [
      null,
      "Lido",
      "Joe V2.1",
      "JustLend",
      "Rocket Pool",
      "stUSDT",
      "AAVE V3",
      "MakerDAO",
      "Binance staked ETH",
      "Spark",
      "SushiSwap",
      "AAVE V2",
      "Frax Ether",
      "Liquity",
      "Compound V3",
      "Orca",
      "Compound",
      "Venus Core Pool",
      "Coinbase Wrapped Staked ETH",
      "Mantle Staked ETH",
      "Swell Liquid Staking",
      "GMX V1",
      "Morpho Aave",
      "Prisma Finance",
      "Curve DEX",
      "Uniswap V3",
      "Stader",
      "Benqi Lending",
      "Balancer V2",
      "Convex Finance",
      "Thorchain",
      "GLIF",
      "Fraxlend",
      "Yearn Finance",
      "GMX V2",
      "VVS Standard",
      "Frax",
      "Arrakis V1",
      "Solend",
      "Instadapp",
      "Origin Ether",
      "Uniswap V2",
      "crvUSD",
      "Radiant V2",
      "StakeWise",
      "Aura",
      "Abracadabra",
      "Goldfinch",
      "Helio Protocol",
      "sDAI",
      "Ankr",
      "Pando Leaf",
      "Tectonic",
      "Kamino Lend",
      "Osmosis DEX",
      "Tangible RWA",
      "PancakeSwap AMM",
      "Vires Finance",
      "PancakeSwap AMM V3",
      "Sommelier",
      "Across",
      "Dot Dot Finance",
      "StakeDAO",
      "BendDAO Lending",
      "Kava Mint",
      "Merkl",
      "Solv Funds",
      "Liqwid",
      "Bifrost Liquid Staking",
      "Credix",
      "DefiChain DEX",
      "xExchange",
      "Amnis Finance",
      "Camelot V3",
      "Astroport",
      "NodeDAO",
      "UwU Lend",
      "Folks Finance Lending",
      "Morpho Compound",
      "Stargate",
      "Reflexer",
      "NAVI Protocol",
      "Bancor V3",
      "Gains Network",
      "Bedrock uniETH",
      "Kamino Liquidity",
      "Pendle",
      "Flux Finance",
      "BabyDogeSwap",
      "CIAN",
      "Raydium",
      "SideShift",
      "Jones DAO",
      "Reserve",
      "Inverse Finance FiRM",
      "Platypus Finance",
      "stake.link liquid",
      "Tranchess Ether",
      "SFT Protocol",
      "Iron Bank",
      "GETH",
      "Atrix",
      "Ferro",
      "Vector Finance",
      "Concentrator",
      "Origin Dollar",
      "SaucerSwap",
      "Alpaca Leveraged Yield Farming",
      "Stride",
      "Gravita Protocol",
      "Unizen",
      "Canto Lending",
      "Index Coop",
      "Badger DAO",
      "Sonne Finance",
      "Yeti Finance",
      "Alpaca Finance 2.0",
      "Beefy",
      "Stafi",
      "MDEX",
      "HashKing",
      "Taiga Acala",
      "Asymetrix Protocol",
      "Wombat Exchange",
      "zkSwap Finance",
      "Yield Yak Aggregator",
      "Velodrome V2",
      "Tokemak",
      "ThalaSwap",
      "Hop Protocol",
      "ParaSpace Lending V1",
      "IQ",
      "Notional V2",
      "Vela Exchange",
      "Toros",
      "Angle",
      "Francium",
      "Clipper",
      "LooksRare",
      "Pangolin",
      "Lodestar V1",
      "Tetu Earn",
      "Tortuga",
      "Strike",
      "HMX",
      "Moonwell",
      "Agave",
      "Beethoven X",
      "Idle",
      "DeltaPrime",
      "Wing Finance",
      "ApertureSwap",
      "Amphor",
      "Synapse",
      "Filet Finance",
      "Bella Protocol",
      "Avault",
      "Kokonut Swap",
      "Ref Finance",
      "Hord",
      "SmarDex",
      "Vesper",
      "Maia DAO",
      "Trader Joe DEX",
      "Ashswap",
      "Nostra mainnet",
      "Extra Finance",
      "Dolomite",
      "IPOR",
      "Gamma",
      "Moonwell Apollo",
      "Loopring",
      "THENA FUSION",
      "Hatom Lending",
      "Nucleon",
      "SpookySwap",
      "Exactly",
      "RealT Tokens",
      "TokensFarm",
      "Meshswap",
      "Dexter",
      "Harvest Finance",
      "WOOFi Earn",
      "sTLOS Liquid Staking",
      "Sovryn Dex",
      "Fractal Protocol",
      "BiFi",
      "Allbridge Classic",
      "ARPA Staking",
      "PepeTeam sWAVES",
      "Stakehouse",
      "Silo Finance",
      "Ribbon",
      "ZeroLend",
      "Hermes Protocol",
      "Solidly V3",
      "Camelot V2",
      "Trader Joe Lend",
      "Archimedes Finance",
      "KordFi",
      "Green Planet",
      "Metavault.Trade",
      "Hipo",
      "Quickswap Dex",
      "Moola Market",
      "Affine DeFi",
      "Teahouse Managed",
      "Vaultka",
      "Clearpool",
      "dForce",
      "Reaper Farm",
      "Interport Finance",
      "HoldStation DeFutures",
      "Tender Finance",
      "Gearbox",
      "Prime Protocol",
      "1inch Network",
      "Umami Finance",
      "Equalizer Exchange",
      "Ramses V2",
      "Ghost",
      "Bolide",
      "Stella",
      "Verse",
      "Cat in a Box",
      "Bunni",
      "unshETH",
      "Pickle",
      "Starlay Finance",
      "BaseSwap V2",
      "THENA V1",
      "Sherlock",
      "STFIL",
      "Granary Finance",
      "Opyn Squeeth",
      "QiDao",
      "Velocimeter V2",
      "Liquis",
      "Ramses V1",
      "Ellipsis Finance",
      "Taiga Karura",
      "Meme Dollar",
      "Spice Finance",
      "ApeSwap Lending",
      "Notional V3",
      "Integral",
      "Scale",
      "Yama Finance",
      "Saddle Finance",
      "ApolloDAO",
      "Morphex V2",
      "Forge",
      "Magpie",
      "Pods Yield",
      "Retro",
      "Raft",
      "NULS POCM",
      "Kagla Finance",
      "ZeroLiquid",
      "Perpetual Protocol",
      "Aurigami",
      "Valas Finance",
      "ACryptoS",
      "GMD Protocol",
      "rhino.fi",
      "SmartCredit",
      "FVM Exchange",
      "Blueshift",
      "Maia V3",
      "Arbitrove",
      "Tenderize",
      "Joe V2",
      "Impermax Finance",
      "Symbiosis",
      "NerveSwap",
      "JPEG'd",
      "Trisolaris",
      "Single Finance",
      "FRAKT",
      "Demex",
      "BetSwirl",
      "Hydradex V3",
      "LST Optimizer",
      "mStable Yield",
      "ApeSwap AMM",
      "Liqee",
      "Premia V2",
      "Swapr",
      "Equilibre",
      "Drops",
      "PsyOptions",
      "Libre Swap",
      "Archly V1",
      "Steer Protocol",
      "Grizzly Trade",
      "Wombex Finance",
      "Arbor Finance",
      "Kokoa Finance",
      "Teahouse Permissionless",
      "Nitron",
      "Phuture",
      "Goose Finance",
      "Conic Finance",
      "Lyra V1",
      "Alien Base AMM",
      "BaseX",
      "StellaSwap V2",
      "Sentiment",
      "QuipuSwap Stableswap",
      "Flashstake",
      "PembRock Finance",
      "Chronos V1",
      "Mellow Protocol",
      "Frigg.eco",
      "Plenty",
      "Paladin Dullahan",
      "Robo-Vault",
      "VeRocket",
      "Blue Planet",
      "fx Protocol",
      "Glacier Exchange V2",
      "Uniwhale",
      "Solidly V2",
      "Radiant V1",
      "Comb Financial",
      "SandClock",
      "OpenLeverage",
      "Primex Finance",
      "Kolibri",
      "Circuit Protocol",
      "stake.link index",
      "Deri Protocol",
      "Predy V5",
      "Zyberswap AMM",
      "Mycelium Perpetual Swaps",
      "GhostMarket",
      "Penrose",
      "AnimeSwap",
      "Mero",
      "Dystopia",
      "NUDES",
      "Olympus DAO",
      "ZipSwap",
      "OneRing V2",
      "MM Finance Polygon",
      "Arbitrum Exchange V3",
      "LionDEX",
      "InsureDAO",
      "Vendor V2",
      "YLDR",
      "KillSwitch",
      "Unipilot",
      "Orange Finance",
      "HedgeFarm",
      "Lodestar V0",
      "TrueFi",
      "CrowdSwap",
      "Ensuro",
      "Arbitrum Exchange V2",
      "StableBase",
      "Reservoir",
      "KyberSwap Elastic",
      "Covo V1",
      "Muuu Finance",
      "Scream",
      "Minto",
      "HorizonDEX",
      "Pinjam Labs",
      "Neutra Finance",
      "HYDT Protocol",
      "Struct Finance",
      "Mover",
      "DFX V2",
      "Matter Defi",
      "wefi",
      "Davos Protocol",
      "3xcalibur",
      "Y2K V1",
      "MMO Finance",
      "Oswap AMM",
      "Liquid Bolt",
      "Covo V2",
      "dAMM Finance",
      "Sharpe Magnum",
      "Tarot",
      "Sense",
      "STRX Finance",
      "Cetus",
      "Seamless Protocol",
      "Aerodrome",
      "Brinc Finance",
    ].map((val) => ({
      label: val || "All Projects",
      value: val,
      icon: "",
    })),
  },
  {
    name: "Chain",
    key: "chain",
    enabled: true,
    placeholder: "Select chains",
    type: POOL,
    rangeType: "select",
    selectList: [
      null,
      "Ethereum",
      "Avalanche",
      "Tron",
      "Arbitrum",
      "Solana",
      "BSC",
      "Bitcoin",
      "Filecoin",
      "Polygon",
      "Cronos",
      "Gnosis",
      "Mixin",
      "Optimism",
      "Osmosis",
      "Waves",
      "Kava",
      "Cardano",
      "Polkadot",
      "Defichain",
      "MultiversX",
      "Aptos",
      "Neutron",
      "Algorand",
      "Sui",
      "Metis",
      "Base",
      "Kusama",
      "Hedera",
      "Celo",
      "Stride",
      "Canto",
      "Acala",
      "zkSync Era",
      "Bitcoincash",
      "Litecoin",
      "Heco",
      "Moonbeam",
      "Doge",
      "Ontology",
      "Manta",
      "Astar",
      "Klaytn",
      "NEAR",
      "Starknet",
      "Moonriver",
      "Cosmos",
      "Conflux",
      "Fantom",
      "Persistence",
      "Telos",
      "Rootstock",
      "Bifrost Network",
      "Bifrost",
      "Tezos",
      "Ton",
      "Mantle",
      "Kujira",
      "Karura",
      "Evmos",
      "Polygon zkEVM",
      "Nuls",
      "Aurora",
      "Milkomeda C1",
      "Linea",
      "Carbon",
      "Hydra",
      "Libre",
      "Boba",
      "Rollux",
      "Boba_Bnb",
      "Fuse",
      "Vechain",
      "Neo",
      "Oasis",
      "Harmony",
      "Obyte",
    ].map((val) => ({
      label: val || "All Chains",
      value: val,
      icon: "",
    })),
  },
  {
    name: "TVL",
    key: "tvl",
    enabled: true,
    minLevel: "Low",
    maxLevel: "High",
    unit: "$",
    desrc: "",
    type: POOL,
  },
  {
    name: "APY",
    key: "apy",
    enabled: true,
    minLevel: "Low",
    maxLevel: "High",
    unit: "%",
    desrc: "",
    type: POOL,
  },
]

export const COLUMNS_TOKENS = [
  {
    title: "#",
    key: "order",
    enabled: true,
    sortable: false,
  },
  {
    title: "",
    key: "action",
    enabled: true,
    sortable: false,
  },
  {
    title: "Name",
    key: "symbol",
    enabled: true,
    sortable: false,
  },
  {
    title: "Price",
    key: "current_price",
    desrc:
      "Current price of a cryptocurrency aggregated across exchanges and markets",
    enabled: true,
    sortable: true,
  },
  {
    title: "Market Cap",
    key: "market_cap",
    desrc:
      "Market Cap = Current Price x Circulating Supply. Total market value of a cryptocurrency's circulating supply",
    enabled: true,
    sortable: true,
  },
  {
    title: "Volatility",
    key: "lunar_volatility",
    desrc:
      "A measure of how much the price has moved up or down over short time periods.",
    enabled: false,
    sortable: true,
  },
  {
    title: "Categories",
    key: "categories",
    desrc: "",
    enabled: false,
    sortable: false,
  },
  {
    title: "Exchanges",
    key: "exchanges",
    desrc: "",
    enabled: false,
    sortable: false,
  },
  {
    title: "Fully Diluted Market Cap",
    key: "fully_diluted_valuation",
    desrc:
      "Fully Diluted Market Cap = Current Price x Total Supply. Theoretical total market value of a cryptocurrency's total supply",
    enabled: false,
    sortable: true,
  },
  {
    title: "24h Volume",
    key: "total_volume",
    desrc:
      "Total sum of actual trades taking place across all tracked platforms in the last 24 hours",
    enabled: true,
    sortable: true,
  },
  {
    title: "24h Price Change",
    key: "price_change_percentage_24h",
    desrc: "Percentage change in price in the last 24 hours",
    enabled: true,
    sortable: true,
  },
  {
    title: "24h MC Change",
    key: "market_cap_change_percentage_24h",
    desrc: "Percentage change in market cap in the last 24 hours",
    enabled: false,
    sortable: true,
  },
  {
    title: "1w Price Change",
    key: "lunar_percent_change_week",
    desrc: "Percentage change in price over past week",
    enabled: false,
    sortable: true,
  },
  {
    title: "Market Dominance",
    key: "lunar_market_dominance",
    desrc:
      "Ratio between the market cap of a cryptocurrency and of all other cryptocurrencies",
    enabled: false,
    sortable: true,
  },
  {
    title: "1h Exchange",
    key: "lunar_exchange_1h",
    desrc: "Total trading value on exchanges in the past hour",
    enabled: false,
    sortable: true,
  },
  {
    title: "24h Exchange",
    key: "lunar_exchange_24h",
    desrc: "Total trading value on exchanges in the past 24 hours",
    enabled: false,
    sortable: true,
  },
  // {
  //   title: "CoinGecko trending",
  //   key: "coingecko_trending",
  // },
  // {
  //   title: "X Trending",
  //   key: "lunar_tweets",
  //   desrc: "Trending score on X",
  // },
  // {
  //   title: "Reddit Trending",
  //   key: "lunar_reddit",
  // },
  {
    title: "Social Volume",
    key: "lunar_social_volume",
    desrc: "Total number of mentions across social platforms",
    enabled: false,
    sortable: true,
  },
  // {
  //   title: "Topic Trending",
  //   key: "lunar_topic_delta",
  // },
  // {
  //   title: "Average Sentiment",
  //   key: "lunar_average_sentiment",
  //   desrc: "Overall bullishness or bearishness on social posts (from 1 to 5)",
  //   enabled: false,
  //   sortable: true,
  // },
  // {
  //   title: "Bullish Sentiment",
  //   key: "lunar_bullish_sentiment",
  //   desrc: "Bullish score on social platforms",
  // },
  // {
  //   title: "Bearish Sentiment",
  //   key: "lunar_bearish_sentiment",
  //   desrc: "Bearish score on social platforms",
  // },
  {
    title: "RSI 7",
    key: "rsi7",
    desrc: "RSI measures momentum (strength) of price movements",
    enabled: false,
    sortable: true,
  },
  {
    title: "RSI 14",
    key: "rsi14",
    desrc: "RSI measures momentum (strength) of price movements",
    enabled: false,
    sortable: true,
  },
  {
    title: "RSI 25",
    key: "rsi25",
    desrc: "RSI measures momentum (strength) of price movements",
    enabled: false,
    sortable: true,
  },
  {
    title: "SMA 5",
    key: "sma5",
    desrc: `The lens calculates the deviation of the current price from the average price over the last [period] days (SMA).
    "Value > 0" when "Price > SMA"
    "Value < 0" when "Price < SMA"`,
    enabled: false,
    sortable: true,
  },
  {
    title: "SMA 20",
    key: "sma20",
    desrc: `The lens calculates the deviation of the current price from the average price over the last [period] days (SMA).
    "Value > 0" when "Price > SMA"
    "Value < 0" when "Price < SMA"`,
    enabled: false,
    sortable: true,
  },
  {
    title: "SMA 100",
    key: "sma100",
    desrc: `The lens calculates the deviation of the current price from the average price over the last [period] days (SMA).
    "Value > 0" when "Price > SMA"
    "Value < 0" when "Price < SMA"`,
    enabled: false,
    sortable: true,
  },
  {
    title: "Lower Bollinger Band",
    key: "lower_bband",
    desrc: `The lens calculates the price deviation from the Lower Bollinger Band (LBB).
    "Value > 0" when "Price > LBB"
    "Value < 0" when "Price < LBB"`,
    enabled: false,
    sortable: true,
  },
  {
    title: "Middle Bollinger Band",
    key: "middle_bband",
    desrc: `The lens calculates the price deviation from the Middle Bollinger Band (MBB).
    "Value > 0" when "Price > MBB"
    "Value < 0" when "Price < MBB"`,
    enabled: false,
    sortable: true,
  },
  {
    title: "Upper Bollinger Band",
    key: "upper_bband",
    desrc: `
    The lens calculates the price deviation from the Upper Bollinger Band (UBB).
    "Value > 0" when "Price > UBB"
    "Value < 0" when "Price < UBB"`,
    enabled: false,
    sortable: true,
  },
  {
    title: "Social Score",
    key: "lunar_social_score",
    desrc:
      "Overall score of social influence of a cryptocurrency across social platforms",
    enabled: true,
    sortable: true,
  },
]

export const COLUMNS_POOLS = [
  {
    title: "#",
    key: "order",
    enabled: true,
    sortable: false,
  },
  {
    title: "",
    key: "action",
    enabled: true,
    sortable: false,
  },
  {
    title: "Pool",
    key: "symbol",
    enabled: true,
    sortable: false,
  },
  {
    title: "Project",
    key: "project_name",
    desrc: "",
    enabled: true,
    sortable: false,
  },
  {
    title: "Chain",
    key: "chain",
    desrc: "",
    enabled: true,
    sortable: false,
  },
  {
    title: "TVL",
    key: "tvl",
    desrc: "",
    enabled: true,
    sortable: true,
  },
  {
    title: "APY",
    key: "apy",
    desrc: "",
    enabled: true,
    sortable: true,
  },
]

export const COLUMNS_ICO = [
  {
    title: "#",
    key: "order",
    enabled: true,
    types: ["upcoming", "active"],
    sortable: true,
  },
  {
    title: "Name",
    key: "name",
    enabled: true,
    types: ["upcoming", "active"],
    sortable: true,
  },
  {
    title: "Initial Cap",
    key: "initialCap",
    enabled: true,
    types: ["upcoming", "active"],
    sortable: true,
    desrc:
      "Initial Cap refers to total market capitalization when it is first introduced to the market, Initial Cap = Initial token price × Unlock Supply",
  },
  {
    title: "Raising",
    key: "raise",
    enabled: true,
    types: ["upcoming", "active"],
    sortable: true,
    desrc: "Public total raise on all launchpads",
  },
  {
    title: "Total Raised",
    key: "totalRaise",
    enabled: true,
    types: ["upcoming", "active"],
    sortable: true,
    desrc:
      "Total Raised Amount is the cumulative funds gathered through Public Sale and various Funding Rounds",
  },
  {
    title: "Start Date",
    key: "when",
    desrc: "",
    enabled: true,
    types: ["upcoming"],
    sortable: true,
  },
  {
    title: "End Date",
    key: "till",
    desrc: "",
    enabled: true,
    types: ["active"],
    sortable: true,
  },
  {
    title: "Launchpad",
    key: "launchpads",
    desrc: "",
    enabled: true,
    types: ["upcoming", "active"],
    sortable: false,
  },
  {
    title: "Category",
    key: "category",
    desrc: "",
    enabled: true,
    types: ["upcoming", "active"],
    sortable: false,
  },
  {
    title: "Website",
    key: "website",
    desrc: "",
    enabled: true,
    types: ["upcoming", "active"],
    sortable: false,
  },
]

export const TOKEN_LENSSET_TYPES = [MARKET, SOCIAL, MOMEMTUM, PATTERN]
export const POOL_LENSSET_TYEPS = [POOL]

export const LENSSET_TYPES = {
  [TypesRanking.TOKEN]: TOKEN_LENSSET_TYPES,
  [TypesRanking.POOL]: POOL_LENSSET_TYEPS,
}

export const LENS_LABEL = {
  OVERSOLD: "Oversold",
  NEUTRAL: "Neutral",
  OVERBOUGHT: "Overbought",

  SMALL_CAP: "Small cap",
  MID_CAP: "Mid cap",
  LARGE_CAP: "Large cap",
}

export enum ALERT_CONDITION {
  GREATER = "greater",
  LESS = "less",
  EVERY_DAY = "every_day",
  EVERY_HOUR = "every_hour",
}

export enum ALERT_METRIC {
  PRICE = "price",
  PRICE_CHANGE = "price_change",
  MARKET_CAP = "market_cap",
  VOLUMN_CHANGE = "volume_change",
  MARKET_DOMINANCE = "market_dominance",
}

export enum ALERT_TIMEFRAME {
  HOUR = "1h",
  DAY = "1d",
}

export enum ALERT_STATUS {
  ACTIVE = "active",
  DEACTIVE = "deactive",
  DELETED = "deleted",
}

export enum ALERT_PLATFORM {
  BROWSER = "browser",
  EMAIL = "email",
}

export const METRICS = [
  {
    label: "Price ($)",
    value: ALERT_METRIC.PRICE,
    unit: "$",
    text: "price",
  },
  {
    label: "Price Change (%)",
    value: ALERT_METRIC.PRICE_CHANGE,
    unit: "%",
    text: "price change",
  },
  {
    label: "Market Cap ($)",
    value: ALERT_METRIC.MARKET_CAP,
    unit: "$",
    text: "market cap",
  },
  // {
  //   label: "Volume change (%)",
  //   value: ALERT_METRIC.VOLUMN_CHANGE,
  // },
  {
    label: "Market Dominance (%)",
    value: ALERT_METRIC.MARKET_DOMINANCE,
    unit: "%",
    text: "market dominance",
  },
]

export const CONDITIONS = {
  [ALERT_METRIC.PRICE]: [
    {
      label: "Above",
      value: ALERT_CONDITION.GREATER,
    },
    {
      label: "Below",
      value: ALERT_CONDITION.LESS,
    },
    {
      label: "Every hour",
      value: ALERT_CONDITION.EVERY_HOUR,
    },
    {
      label: "Every day",
      value: ALERT_CONDITION.EVERY_DAY,
    },
  ],
  [ALERT_METRIC.PRICE_CHANGE]: [
    {
      label: "Increase",
      value: ALERT_CONDITION.GREATER,
    },
    {
      label: "Decrease",
      value: ALERT_CONDITION.LESS,
    },
  ],
  [ALERT_METRIC.MARKET_CAP]: [
    {
      label: "Above",
      value: ALERT_CONDITION.GREATER,
    },
    {
      label: "Below",
      value: ALERT_CONDITION.LESS,
    },
  ],
  [ALERT_METRIC.VOLUMN_CHANGE]: [
    {
      label: "Increase",
      value: ALERT_CONDITION.GREATER,
    },
    {
      label: "Decrease",
      value: ALERT_CONDITION.LESS,
    },
  ],
  [ALERT_METRIC.MARKET_DOMINANCE]: [
    {
      label: "Above",
      value: ALERT_CONDITION.GREATER,
    },
    {
      label: "Below",
      value: ALERT_CONDITION.LESS,
    },
  ],
}

export const CANDLE_BULLISH_GROUP = [
  "bullish_harami",
  "bullish_engulfing",
  "bullish_doji_star",
  "bullish_dragonfly_doji",
  "bullish_gravestone_doji",
  "bullish_hammer",
  "bullish_inverted_hammer",
  "bullish_morning_doji_star",
  "bullish_morning_star",
  "bullish_piercing",
]

export const CANDLE_BEARISH_GROUP = [
  "bearish_harami",
  "bearish_engulfing",
  "bearish_doji_star",
  "bearish_dragonfly_doji",
  "bearish_gravestone_doji",
  "bearish_hammer",
  "bearish_inverted_hammer",
  "bearish_evening_doji_star",
  "bearish_evening_star",
  "bearish_hanging_man",
  "bearish_darkcloud_cover",
  "bearish_shooting_star",
]

export const PATTERN_DESCRIPTIONS = {
  basic_doji: `Doji candlesticks are considered to be neutral indicators, as they suggest indecision in the market between buyers and sellers.`,
  dragonfly_doji: `The dragonfly doji suggests indecision in the market, with selling pressure being initially strong but ultimately counterbalanced by buying pressure. Appearing after a downtrend, it signals a potential bullish reversal, indicating buyers might be gaining control and pushing the price upwards.`,
  bullish_dragonfly_doji: `The dragonfly doji suggests a reversal, with selling pressure being initially strong but ultimately counterbalanced by buying pressure. Appearing after a downtrend, it signals a potential bullish reversal, indicating buyers might be gaining control and pushing the price upwards.`,
  bearish_dragonfly_doji: `The dragonfly doji suggests indecision in the market, with selling pressure being initially strong but ultimately counterbalanced by buying pressure. Appearing after a downtrend, it signals a potential bullish reversal, indicating buyers might be gaining control and pushing the price upwards. The "brearish" term indicates bearish market.`,
  gravestone_doji: `The gravestone doji suggests indecision in the market, with initial buying pressure being overwhelmed by selling pressure later in the session. Appearing after an uptrend, it signals a potential bearish reversal, indicating sellers might be gaining control and pushing the price downwards.`,
  bullish_gravestone_doji: `The gravestone doji suggests indecision in the market, with initial buying pressure being overwhelmed by selling pressure later in the session. Appearing after an uptrend, it signals a potential bearish reversal, indicating sellers might be gaining control and pushing the price downwards. The "bullish" term indicates bullish market.`,
  bearish_gravestone_doji: `The gravestone doji suggests indecision in the market, with initial buying pressure being overwhelmed by selling pressure later in the session. Appearing after an uptrend, it signals a potential bearish reversal, indicating sellers might be gaining control and pushing the price downwards.`,
  hammer: `The hammer suggests indecision in the market, with initial selling pressure being challenged and ultimately countered by buying pressure. Appearing at the bottom of a downtrend, it signals a potential bullish reversal, indicating buyers might be gaining control and pushing the price upwards.`,
  bullish_hammer: `A hammer is a bullish reversal pattern. It occurs at the end of a downtrend when the bears start losing their dominance.`,
  bearish_hammer: `The hammer suggests indecision in the market, with initial selling pressure being challenged and ultimately countered by buying pressure. Appearing at the bottom of a downtrend, it signals a potential bullish reversal, indicating buyers might be gaining control and pushing the price upwards. The "bullish" term indicates bullish market.The "bearish" term indicates bearish market.`,
  inverted_hammer: `The inverted hammer suggests indecision in the market, with initial selling pressure being challenged and ultimately countered by buying pressure.
  Appearing at the bottom of a downtrend, it signals a potential bullish reversal, indicating buyers might be gaining control and pushing the price upwards.`,
  bullish_inverted_hammer: `The inverted hammer suggests a bullish reversal in the market. It means the price hesitated to move downward and the buyers are testing the power of the sellers.`,
  bearish_inverted_hammer: `The inverted hammer suggests indecision in the market, with initial selling pressure being challenged and ultimately countered by buying pressure.
  Appearing at the bottom of a downtrend, it signals a potential bullish reversal, indicating buyers might be gaining control and pushing the price upwards. The "bearish" term indicates bearish market.`,
  evening_doji_star: `The evening doji star pattern suggests a potential shift in momentum from an uptrend to a downtrend. The first candle reflects the continuation of the uptrend, followed by the doji indicating indecision. The third, long bearish candle signifies a resurgence of selling pressure, potentially breaking the uptrend and leading to a price decline.`,
  evening_star: `The evening star pattern suggests a potential shift in momentum from an uptrend to a downtrend. The first candle reflects the continuation of the uptrend, followed by the small-bodied second candle with a gap down, indicating weakening buying pressure. The third, long bearish candle signifies a resurgence of selling pressure, potentially breaking the uptrend and leading to a price decline.`,
  morning_doji_star: `The morning doji star pattern suggests a potential shift in momentum from a downtrend to an uptrend. The first candle reflects the continuation of the downtrend. The doji in the second candle suggests indecision, indicating a possible weakening of the downtrend. The third, long bullish candle signifies a resurgence of buying pressure, potentially breaking the downtrend and leading to a price increase.`,
  morning_star: `The morning star pattern suggests a potential shift in momentum from a downtrend to an uptrend. The first candle reflects the continuation of the downtrend, followed by the small-bodied second candle with a gap down, indicating a pause in the selling pressure. The third, long bullish candle signifies a surge in buying pressure, potentially breaking the downtrend and leading to a price increase.`,
  hanging_man: `The hanging man pattern suggests a potential exhaustion of buying pressure and a possible shift in momentum towards selling pressure.`,
  bullish_engulfing: `The engulfing pattern suggests a significant shift in power between buyers and sellers. The larger body of the second candle reflects increased buying pressure.`,
  bearish_engulfing: `The engulfing pattern suggests a significant shift in power between buyers and sellers. The larger body of the second candle reflects increased selling pressure.`,
  bullish_harami: `The harami pattern suggests a temporary pause or hesitation in the prevailing trend, potentially indicating a shift in momentum. The smaller, contained candle represents a weaker continuation of the initial trend, while its engulfment by the larger candle signifies a possible counteracting force. The bullish candle engulfed within a bearish candle suggests emerging buying pressure that might challenge the downtrend and lead to a potential upward reversal.`,
  bearish_harami: `The harami pattern suggests a temporary pause or hesitation in the prevailing trend, potentially indicating a shift in momentum. The smaller, contained candle represents a weaker continuation of the initial trend, while its engulfment by the larger candle signifies a possible counteracting force. The bearish candle engulfed within a bullish candle suggests emerging selling pressure that might challenge the uptrend and lead to a potential downward reversal.`,
  darkcloud_cover: `The dark cloud cover pattern suggests a potential exhaustion of buying pressure and a shift in momentum towards selling pressure.`,
  bullish_doji_star: `The bullish doji star pattern suggests a potential exhaustion of selling pressure and a possible shift in momentum towards buying pressure.`,
  bearish_doji_star: `The bearish doji star pattern suggests a potential exhaustion of buying pressure and a possible shift in momentum towards selling pressure.`,
  raindrop: `The raindrop candlestick pattern is a relatively new technical analysis tool used by some traders to identify potential changes in market sentiment, though it is not as widely recognized as traditional candlestick patterns. It is built upon the foundation of volume-weighted average price (VWAP) and aims to provide a more comprehensive picture of price movement and volume activity compared to traditional candlesticks.`,
  raindrop_doji: `Similar to a traditional doji candle, a raindrop doji suggests indecision in the market, with neither buyers nor sellers able to establish dominance throughout the trading period.`,
  piercing: `The piercing candle pattern suggests a potential exhaustion of selling pressure followed by a surge in buying pressure, indicating a possible reversal from the downtrend`,
  shooting_star: `The shooting star pattern suggests a potential exhaustion of buying pressure and a possible shift in momentum towards selling pressure`,
  star: `A star is a type of candlestick formation that is identified when a small-bodied candle is positioned above the price range of the previous candle as a result of a gap in the underlying asset price.`,
  bearish_evening_doji_star: `The evening doji star pattern suggests a potential shift in momentum from an uptrend to a downtrend. The first candle reflects the continuation of the uptrend, followed by the doji indicating indecision. The third, long bearish candle signifies a resurgence of selling pressure, potentially breaking the uptrend and leading to a price decline.
  `,
  bearish_evening_star: `The evening star pattern suggests a potential shift in momentum from an uptrend to a downtrend. The first candle reflects the continuation of the uptrend, followed by the small-bodied second candle with a gap down, indicating weakening buying pressure. The third, long bearish candle signifies a resurgence of selling pressure, potentially breaking the uptrend and leading to a price decline.`,
  bullish_morning_doji_star: `The morning doji star pattern suggests a potential shift in momentum from a downtrend to an uptrend. The first candle reflects the continuation of the downtrend. The doji in the second candle suggests indecision, indicating a possible weakening of the downtrend. The third, long bullish candle signifies a resurgence of buying pressure, potentially breaking the downtrend and leading to a price increase.`,
  bullish_morning_star: `
  The morning star pattern suggests a potential shift in momentum from a downtrend to an uptrend. The first candle reflects the continuation of the downtrend, followed by the small-bodied second candle with a gap down, indicating a pause in the selling pressure. The third, long bullish candle signifies a surge in buying pressure, potentially breaking the downtrend and leading to a price increase.
  `,
  bearish_hanging_man: `The hanging man pattern suggests a potential exhaustion of buying pressure and a possible shift in momentum towards selling pressure.`,
  bearish_darkcloud_cover: `The dark cloud cover pattern suggests a potential exhaustion of buying pressure and a shift in momentum towards selling pressure.`,
  bullish_piercing: `The piercing candle pattern suggests a potential exhaustion of selling pressure followed by a surge in buying pressure, indicating a possible reversal from the downside to the upside might happen soon.`,
  bearish_shooting_star: `The shooting star pattern suggests a potential exhaustion of buying pressure and a possible shift in momentum towards selling pressure.`,
}

export const COLUMNS_MARKETS_TOKEN = [
  {
    title: "#",
    key: "order",
    enabled: true,
    sortable: true,
  },
  {
    title: "Exchange",
    key: "exchange",
    enabled: true,
    sortable: true,
  },
  {
    title: "Pair",
    key: "pair",
    enabled: true,
    sortable: true,
  },
  // {
  //   title: "Price",
  //   key: "price",
  //   enabled: true,
  //   sortable: true,
  // },
  {
    title: "Volume (24h)",
    key: "volume_24h",
    enabled: true,
    sortable: true,
  },
  {
    title: "Volume %",
    key: "volume_percent",
    enabled: true,
    sortable: true,
  },
  {
    title: "Liquidity",
    key: "liquidity",
    enabled: true,
    sortable: true,
  },
]
