import { SOCKET_EVENT_NAME } from "@constants/index"
import { updateMarketPrice } from "@reducers/defiLens/QuickTradeSlice"
import { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { Socket, io } from "socket.io-client"

const isDevelopment = import.meta.env.VITE_APP_ENV_MODE == "staging"

let connectionCount = 0
let socket: Socket | null = null
const useDataLabWebSocket = (
  // eventName?: string,
  callback?: (message: any) => void,
) => {
  const baseURL = isDevelopment
    ? "wss://staging-data-lab-center.orai.io/"
    : "wss://data-lab-center.orai.io/"
  const path = "/data-lab-socket"
  const [msgSocket, setMsgSocket] = useState(null)
  const isFirstInstance = useRef<boolean>(true)
  const dispatch = useDispatch()
  const isFirstUpdatePrice = useRef<boolean>(true)

  useEffect(() => {
    if (isFirstInstance.current) {
      connectionCount += 1

      if (connectionCount === 1) {
        socket = io(baseURL as string, {
          path,
          transports: ["websocket"],
          rejectUnauthorized: false,
          agent: false,
        })

        if (socket) {
          socket.on("connect", () => {
            console.log("Connected data lab websocket")
          })

          socket.on("connect_error", (e: any) => {
            console.log(e, "connect_error")
          })
        }
      }

      isFirstInstance.current = false
    }

    if (socket) {
      socket.on(SOCKET_EVENT_NAME.BINANCE_MARKET_PRICE, (message: any) => {
        setMsgSocket(message)
        callback && callback(message)

        if (message && message.length > 0 && isFirstUpdatePrice.current) {
          dispatch(updateMarketPrice(message))
          isFirstUpdatePrice.current = false
        }
      })
    }

    return () => {
      if (socket) {
        socket.off(SOCKET_EVENT_NAME.BINANCE_MARKET_PRICE)
      }
    }
  }, [])

  return { msgSocket, socket }
}

export default useDataLabWebSocket
