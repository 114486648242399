import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface AlertsState {
  alertList: any[]
  alertHistoryList: any[]
}

const initialState: AlertsState = {
  alertList: [],
  alertHistoryList: [],
}

const AlertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    updateAlertList(state, action: PayloadAction<AlertsState["alertList"]>) {
      return {
        ...state,
        alertList: action.payload,
      }
    },
    updateAlertHistoryList(
      state,
      action: PayloadAction<AlertsState["alertHistoryList"]>,
    ) {
      return {
        ...state,
        alertHistoryList: action.payload,
      }
    },
  },
})

export const { updateAlertList, updateAlertHistoryList } = AlertsSlice.actions

export default AlertsSlice.reducer
