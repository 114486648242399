import useDataLabWebSocket from "@hooks/useDataLabWebSocket"
import defineElement from "@utils/defineElement"

export function RequireAuth({
  children,
}: {
  children: JSX.Element
  isAuthenticated?: boolean
}) {
  const { socket } = useDataLabWebSocket()
  return defineElement(children, {
    socket,
  })
}
