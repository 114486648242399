import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface ExpandState {
  isExpand: boolean
}

const initialState: ExpandState = {
  isExpand: false,
}

const ExpandSlice = createSlice({
  name: "expand",
  initialState,
  reducers: {
    updateStatusExpand(state, action: PayloadAction<ExpandState["isExpand"]>) {
      return {
        ...state,
        isExpand: action.payload,
      }
    },
  },
})

export const { updateStatusExpand } = ExpandSlice.actions

export default ExpandSlice.reducer
