import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

interface PoolWatchListState {
  isPoolWatchListModalOpen: boolean
  loading: boolean
  poolFavoriteList: any[]
  poolWatchListInfo: any
  poolFavoriteInWatchList: any[]
}

const initialState: PoolWatchListState = {
  isPoolWatchListModalOpen: false,
  loading: false,
  poolFavoriteList: [],
  poolFavoriteInWatchList: [],
  poolWatchListInfo: {
    page: 1,
    offset: 0,
    size: 10,
    total: 0,
  },
}

const PoolWatchListSlice = createSlice({
  name: "pool-watch-list",
  initialState,
  reducers: {
    updateStatusPoolFavoriteModal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isPoolWatchListModalOpen: action.payload,
      }
    },

    updatePoolFavoriteList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        poolFavoriteList: action.payload,
      }
    },

    updatePoolWatchListInfo: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        poolWatchListInfo: {
          ...state.poolWatchListInfo,
          ...action.payload,
        },
      }
    },

    updatePoolFavoriteInWatchList: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        poolFavoriteInWatchList: action.payload,
      }
    },
  },
})

export const {
  updateStatusPoolFavoriteModal,
  updatePoolFavoriteList,
  updatePoolWatchListInfo,
  updatePoolFavoriteInWatchList,
} = PoolWatchListSlice.actions

export default PoolWatchListSlice.reducer
