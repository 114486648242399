import { useAuth0 } from "@auth0/auth0-react"
import FallbackLoading from "@components/FallbackLoading"
import mixpanel from "mixpanel-browser"
import { Suspense, useEffect, useState } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import { postAuthLogin } from "services/defiLens"
import TokenService from "services/token"
import { PATHS_NAME } from "./constants"
import RoutesConfig from "./routes"
const mixpanelToken = import.meta.env.VITE_APP_MIXPANEL_TOKEN
const envMode = import.meta.env.VITE_APP_ENV_MODE

function App() {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0()
  const accessToken = TokenService.getLocalAccessToken()
  const userInfo = TokenService.getUserLocal()
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false)

  const dispatchEventStorage = () => {
    const event = new StorageEvent("storage", {
      key: "login",
    })
    return window.dispatchEvent(event)
  }

  const callGetAccessTokenSilently = async () => {
    new URL(location.href).searchParams.get("referredByCode")
    const params = new URL(location.href).searchParams
    const referredByCode = params.get("referredByCode")
    setIsLoginLoading(true)
    try {
      const res = await getAccessTokenSilently({
        detailedResponse: true,
      })
      const authResponse = await postAuthLogin({
        auth0Token: res?.access_token,
        referredByCode: referredByCode,
      })

      if (authResponse.success === false) {
        TokenService.removeUserLocal()

        if (authResponse?.message == "Invalid email") {
          return (window.location.href = `${window.location.origin}/${window.location.search}&loginError=true`)
        }
      }

      TokenService.setUserLocal({
        ...user,
        userId: authResponse?.userId,
        token: authResponse?.token,
      })

      dispatchEventStorage()
      return (window.location.href = `${window.location.origin}${PATHS_NAME.LLM}`)
    } catch (error) {
      console.log("error", error)
    } finally {
      setIsLoginLoading(false)
    }
  }

  useEffect(() => {
    if (isAuthenticated && !accessToken) callGetAccessTokenSilently()
  }, [isAuthenticated, accessToken])

  const initMixpanel = () => {
    mixpanel.init(mixpanelToken, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    })
  }

  useEffect(() => {
    if (envMode === "production") {
      initMixpanel()
    }
  }, [])

  useEffect(() => {
    const win = window as any
    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        ;(win.Featurebase.q = win.Featurebase.q || []).push(arguments)
      }
    }
    win.Featurebase("initialize_feedback_widget", {
      organization: "llmhome",
      theme: "light",
      placement: "right",
      email: userInfo?.email,
      name: userInfo?.name,
    })
  }, [userInfo?.email, userInfo?.name])

  return (
    <Router>
      <Suspense fallback={<FallbackLoading />}>
        <RoutesConfig
          isAuthenticated={accessToken || isAuthenticated}
          isLoginLoading={isLoginLoading}
        />
      </Suspense>
    </Router>
  )
}

export default App
