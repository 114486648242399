import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import { LensMode } from "types/defiLens"

interface PoolRankSliceState {
  poolList: Array<any>
  isLoading: boolean
  filtersPool: {
    keyword: string
    page: number
    offset: number
    total: number
    mode: LensMode
    [key: string]: any
  }
}

const initialState: PoolRankSliceState = {
  poolList: [],
  isLoading: false,
  filtersPool: {
    keyword: "",
    page: 1,
    offset: 0,
    size: 50,
    total: 0,
    mode: LensMode.FILTER,
  },
}

const PoolRankSlice = createSlice({
  name: "pool-ranking",
  initialState,
  reducers: {
    updatePoolList: (state, action: PayloadAction<[]>) => {
      return {
        ...state,
        poolList: action.payload,
      }
    },
    updatePoolLoading: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
    setTablePoolDefault: (state) => {
      return {
        ...state,
        ...initialState,
      }
    },
    updateFiltersPool: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        filtersPool: {
          ...state.filtersPool,
          ...action.payload,
        },
      }
    },
  },
})

export const {
  updatePoolList,
  updatePoolLoading,
  setTablePoolDefault,
  updateFiltersPool,
} = PoolRankSlice.actions

export default PoolRankSlice.reducer
